
import { TripCity } from '@/services/trips/trip';
import { computed, defineComponent, PropType } from 'vue';
import InlineDuration from '@/components/utils/InlineDuration.vue';

export default defineComponent({
  components: {
    InlineDuration,
  },
  props: {
    modelValue: {
      type: Object as PropType<TripCity>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const nights = computed({
      get() {
        return props.modelValue.nights || 0;
      },
      set(v: number) {
        const clone = { ...props.modelValue };
        clone.nights = v;
        emit('update:modelValue', clone);
      },
    });

    return { nights };
  },
});
