import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_open_layer_marker = _resolveComponent("open-layer-marker")!
  const _component_open_layer_polyline = _resolveComponent("open-layer-polyline")!

  return (_openBlock(), _createElementBlock("div", {
    class: "ol-map ed-map flex-fill",
    id: "ol-map",
    tabindex: "-1",
    onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.focusMap && _ctx.focusMap(...args))),
    ref: "mapDiv"
  }, [
    (_ctx.map)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.markerPoints, (marker) => {
          return (_openBlock(), _createBlock(_component_open_layer_marker, _mergeProps(marker, { map: _ctx.map }), null, 16, ["map"]))
        }), 256))
      : _createCommentVNode("", true),
    (_ctx.map)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.polylines, (line) => {
          return (_openBlock(), _createBlock(_component_open_layer_polyline, _mergeProps(line, { "vector-source": _ctx.polylineSource }), null, 16, ["vector-source"]))
        }), 256))
      : _createCommentVNode("", true)
  ], 544))
}