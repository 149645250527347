
//TODO don't disable
/* eslint-disable */
import Map from 'ol/Map';
import View from 'ol/View';
import MVT from 'ol/format/MVT';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import VectorLayer from 'ol/layer/Vector';
import MapboxVectorLayer from 'ol/layer/MapboxVector';
import VectorSource from 'ol/source/Vector';
import { DI } from '@/di';
import { PointSetCollapse } from '@/parts/PointSetCollapse';
import { PolylineFinder } from '@/parts/PolylineFinder';
import { MapDisplayInfoService } from '@/services/map-service/display-info/map-display-info.interface';
import { defineComponent, onMounted, ref, Ref, shallowRef } from 'vue';
import olms from '@/olms-shim';
import STYLE from '@/style.json';
import OpenLayerMarker from './OpenLayerMarker.vue';
import OpenLayerPolyline from './OpenLayerPolyline.vue';
import { MapVectorJsonService } from '@/services/map-service/map-vector-json.interface';
import { fromLonLat } from 'ol/proj';
import { OpenLayersMapDisplayInfoService } from '@/services/map-service/display-info/openlayers-map-display-info.service';
import Geometry from 'ol/geom/Geometry';

const MIN_UPDATE_MILLIS = 50;

export default defineComponent({
  name: 'OpenLayersMap',
  components: { OpenLayerMarker, OpenLayerPolyline },
  setup() {
    const mapDiv: Ref<HTMLElement | null> = ref(null);
    const vectorService = DI.get<MapVectorJsonService>(MapVectorJsonService);
    const displayInfoService = (function () {
      const raw = DI.get<MapDisplayInfoService>(MapDisplayInfoService);
      if (raw instanceof OpenLayersMapDisplayInfoService) return raw;
      return undefined;
    })();
    let map: Ref<Map | null> = shallowRef(null);
    let polylineSource: Ref<VectorSource<Geometry> | null> = shallowRef(null);

    let collapser = new PointSetCollapse();
    let polyFinder = new PolylineFinder();

    function focusMap(evt: MouseEvent) {
      const btns = evt.buttons !== undefined ? evt.buttons : evt.which;
      // Don't focus if a mouse button is down
      if (btns !== 0) return;
      const elem = map.value?.getTargetElement();
      if (elem) elem.focus();
    }

    let lastUpdate: number | null = null;

    //TODO would we be better off triggering this externally?
    //The timer makes sure it doesn't run too often, but it's not ideal
    function resized() {
      const now = Date.now();
      const delta = lastUpdate ? now - lastUpdate : MIN_UPDATE_MILLIS;
      if (delta >= MIN_UPDATE_MILLIS) {
        map.value?.updateSize();
        lastUpdate = now;
      } else {
        setTimeout(resized, MIN_UPDATE_MILLIS - delta);
      }
    }

    function getTileLayer() {
      if (vectorService.isMapboxVectorStyle) {
        return new MapboxVectorLayer({ styleUrl: vectorService.getStyleUrl() });
      } else {
        return new VectorTileLayer({
          declutter: true,
          source: new VectorTileSource({
            attributions: vectorService.getOpenLayersAttributions(),
            format: new MVT(),
            url: vectorService.buildOpenLayersTileUrl(),
          }),
        });
      }
    }

    onMounted(() => {
      const tileLayer = getTileLayer();
      polylineSource.value = new VectorSource({});
      const polylineLayer = new VectorLayer({
        source: polylineSource.value,
        zIndex: 1,
      });
      const theMap = new Map({
        layers: [polylineLayer, tileLayer],
        target: 'ol-map',
        view: new View({
          center: fromLonLat([-0.1276474, 51.5073219]),
          zoom: 10,
        }),
      });

      olms(tileLayer, STYLE, 'openmaptiles');
      map.value = theMap;
      displayInfoService?.setMap(theMap);

      const observer = new ResizeObserver(resized);
      if (mapDiv.value) observer.observe(mapDiv.value);
      else {
        console.error('Map div not ready');
      }
    });

    return {
      markerPoints: collapser.points,
      polylines: polyFinder.lines,
      polylineSource,
      map,
      mapDiv,
      focusMap,
    };
  },
});
