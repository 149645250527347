import { MapPoint } from '@/map-display';
import { RouteType } from './route-type';

export const DirectionRouterService = Symbol('DirectRouterService');

export interface RouteLeg {
  points: MapPoint[];
  seconds: number;
  type: RouteType;
}

export type RouteOptions = RouteLeg[][];

export interface DirectionRouterService {
  getRoutedPoints(points: MapPoint[]): Promise<RouteOptions>;
}
