import { DI } from '@/di';
import {
  TimezoneIdentifier,
  TimezoneLookupService,
} from '@/services/timezone/timezone-lookup.interface';
import { TripCity, TripCityPoint, TripOptions } from '@/services/trips/trip';
import { AsyncRef } from '@/utils/async-ref';
import { NormalizedList, normalizeList } from '@/utils/normalized';
import { DateTime } from 'luxon';
import { computed, ComputedRef, Ref, toRef } from 'vue';
import { CityPointAnnotator } from './city-compute';
import { IntraCityRoute, RoutedCityData } from './city-router';

export interface PointTimeInfo {
  id: string;
  startTime: DateTime;
  endTime: DateTime;
}

export class PointTimeInfoCalculator {
  readonly timeInfo: ComputedRef<NormalizedList<PointTimeInfo>>;
  readonly timezone: AsyncRef<TimezoneIdentifier>;
  readonly tzService: TimezoneLookupService = DI.get<TimezoneLookupService>(
    TimezoneLookupService,
  );
  constructor(
    private readonly city: Ref<TripCity>,
    private readonly annotator: CityPointAnnotator,
    private readonly routeData: Ref<RoutedCityData>,
    private readonly options: Ref<TripOptions | undefined> | undefined,
  ) {
    const lat = toRef(this.city.value, 'lat');
    const lng = toRef(this.city.value, 'lng');
    this.timezone = new AsyncRef(
      [lat, lng],
      () => 'America/Vancouver',
      async () => {
        return this.tzService.getTimezone({ lat: lat.value, lng: lng.value });
      },
    );
    this.timeInfo = computed(() => this.buildTimeInfo());
  }

  createTime(hour?: number, minute?: number): DateTime {
    return DateTime.fromObject(
      {
        hour,
        minute,
      },
      { zone: this.timezone.value },
    );
  }

  buildTimeInfo(): NormalizedList<PointTimeInfo> {
    if (this.routeData.value.days.length === 0) {
      return normalizeList([]);
    }
    const tripOpts = this.options?.value;
    const dayStart = this.createTime(
      tripOpts?.dayStartHour,
      tripOpts?.dayStartMinute,
    );
    const firstDay = [this.city.value.enter_via];
    const days = firstDay.concat(
      this.annotator.getWithVerifiedOvernights().filter((p) => p.is_overnight),
    );
    const identPoints = Array.from(
      this.cityRouteTimeInfo(days, this.routeData.value.days, dayStart),
    );
    while (identPoints.length < this.city.value.points.length) {
      identPoints.push({
        ...identPoints[identPoints.length - 1],
        id: this.city.value.points[identPoints.length].id,
      });
    }
    return normalizeList(identPoints);
  }

  *oneRouteTimeInfo(
    day: IntraCityRoute,
    dayStart: DateTime,
  ): Generator<PointTimeInfo> {
    if (day.details.length === 0) return;

    const fullTime = day.details.reduce(
      (sum, c) =>
        sum +
        (c.from.minutes || 0) * 60 +
        (c.chosen && c.chosen.seconds > 0 ? c.chosen.seconds : 0),
      0,
    );

    yield {
      id: day.details[0].from.id,
      startTime: dayStart,
      endTime: dayStart.plus({ minutes: Math.ceil(fullTime / 60.0) }),
    };

    let currentTime = dayStart;
    for (let i = 0; i < day.details.length; ++i) {
      const detail = day.details[i];
      const endTime = currentTime.plus({ minutes: detail.from.minutes });
      if (i != 0)
        yield { id: day.details[i].from.id, startTime: currentTime, endTime };
      currentTime = endTime.plus({
        minutes: Math.ceil(detail.chosen.seconds / 60.0),
      });
    }
  }

  *cityRouteTimeInfo(
    days: (TripCityPoint | null)[],
    routes: IntraCityRoute[],
    defaultStart: DateTime,
  ): Generator<PointTimeInfo> {
    if (days.length != routes.length)
      throw new Error(
        `Cannot calculate time info with mismatched day/route lengths (${days.length}/${routes.length})`,
      );
    for (let i = 0; i < days.length; ++i) {
      const day = days[i];
      let start = defaultStart;
      if (day?.start_hour !== undefined && day?.start_minute !== undefined) {
        start = this.createTime(day.start_hour, day.start_minute);
      }
      yield* this.oneRouteTimeInfo(routes[i], start);
    }
  }
}
