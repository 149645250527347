import { TripCity, TripCityPoint } from '@/services/trips/trip';
import { ComputedRef, computed, Ref } from 'vue';
import { CityPointAnnotator } from './city-compute';

export class CityDayListFinder {
  readonly dayLists: ComputedRef<TripCityPoint[][]>;
  constructor(
    private readonly city: Ref<TripCity>,
    private readonly annotator: CityPointAnnotator,
  ) {
    this.dayLists = computed(() => this.getCityDayLists());
  }

  protected getCityDayLists(): TripCityPoint[][] {
    const dayCount = this.city.value.nights + 1;
    const points = Array.from({ length: dayCount });
    const lists = points.map((pl, i) => {
      if (i === 0) {
        if (this.city.value.enter_via) {
          return [this.city.value.enter_via];
        } else if (this.city.value.stay_at) {
          return [this.city.value.stay_at];
        }
      }
      return [];
    });

    this.annotator.getAnnotatedPoints().forEach((point) => {
      lists[point.day].push(point.point);
    });

    lists.forEach((l, day) => {
      const isLastDay = day === dayCount - 1;
      if (isLastDay) {
        if (this.city.value.exit_via) {
          l.push(this.city.value.exit_via);
        } else if (this.city.value.stay_at) {
          l.push(this.city.value.stay_at);
        }
      } else if (lists[day + 1][0]) {
        // Take the first point from the next day. That's always the overnight
        // point in the annotator output
        l.push(lists[day + 1][0]);
      }
    });

    return lists;
  }
}
