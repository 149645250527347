export const BASE_PIN_COLOR = '#3822a8';
export const SEARCH_HIGHLIGHT_PIN_COLOR = '#8295ff';
export const CITY_STAY_AT_PIN_COLOR = '#ba7600';
export const CITY_STAY_AT_PIN_HIGHLIGHT = '#d08600';
export const CITY_VIA_PIN_COLOR = '#e87f2e';
export const CITY_VIA_PIN_HIGHLIGHT = '#ff9f30';

export const CITY_PIN_ICON_CLASS = 'fas fa-city';
export const SEARCH_PIN_ICON_CLASS = 'fas fa-search';
export const GENERIC_TRIP_POINT_ICON_CLASS = 'fas fa-map-pin';

export const CITY_PIN_Z_INDEX = 100;
export const SEARCH_PIN_Z_INDEX = 200;

export const BASE_PATH_COLOR = 'black';
export const IN_CITY_PATH_COLOR = '#8b5fc9';
export const CITY_VIA_PATH_COLOR = '#ff8969';

export const CITY_DAY_COLORS = [
  '#B01515',
  '#D4A200',
  '#087A00',
  '#17BD8B',
  '#00DDFF',
  '#1522D6',
  '#801094',
  '#E01991',
];

export const CITY_DAY_HIGHLIGHTS = [
  '#D03535',
  '#F4C230',
  '#28BB30',
  '#37DDAB',
  '#77FFFF',
  '#4562FF',
  '#A030C8',
  '#FF69C4',
];
