
import { TripOptions } from '@/services/trips/trip';
import { defineComponent, PropType } from 'vue';
import { DatePicker } from 'v-calendar';
import { DateTime } from 'luxon';

import { SettableProps } from '@/parts/SettableProps';

export default defineComponent({
  name: 'TripOptionConfig',
  components: {
    DatePicker,
  },
  props: {
    modelValue: {
      type: Object as PropType<TripOptions>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const settableProps = new SettableProps(
      () => props.modelValue,
      emit,
      'update:modelValue',
    );
    const hour = settableProps.getPropRef('dayStartHour', parseInt);
    const minute = settableProps.getPropRef('dayStartMinute', parseInt);

    function* timeOptions(maxExclusive: number) {
      let i = 0;
      for (; i < maxExclusive; ++i) {
        yield { num: i, str: String(i).padStart(2, '0') };
      }
    }
    const hourOptions = Array.from(timeOptions(24));
    const minuteOptions = Array.from(timeOptions(60));

    function updateStartDate(newDate: Date) {
      const updateTo = newDate ? DateTime.fromJSDate(newDate) : undefined;
      const updated: TripOptions = {
        ...props.modelValue,
        startDate: updateTo,
      };
      emit('update:modelValue', updated);
    }

    return {
      hour,
      minute,
      hourOptions,
      minuteOptions,
      updateStartDate,
    };
  },
});
