import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "ed-map",
  id: "leaflet-map",
  tabindex: "-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_map_marker = _resolveComponent("map-marker")!
  const _component_map_polyline = _resolveComponent("map-polyline")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.map)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.markerPoints, (marker) => {
          return (_openBlock(), _createBlock(_component_map_marker, _mergeProps(marker, { map: _ctx.map }), null, 16, ["map"]))
        }), 256))
      : _createCommentVNode("", true),
    (_ctx.map)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.polylines, (line) => {
          return (_openBlock(), _createBlock(_component_map_polyline, _mergeProps(line, { map: _ctx.map }), null, 16, ["map"]))
        }), 256))
      : _createCommentVNode("", true)
  ]))
}