import axios, { AxiosError } from 'axios';
import { injectable } from 'inversify';
import { readonly, ref, Ref } from 'vue';
import { UserInfo, UserInfoService } from './user-info.interface';

//TODO cache user info
@injectable()
export class BasicUserInfoService implements UserInfoService {
  private readonly userRef: Ref<UserInfo | null> = ref(null);
  private loader: Promise<UserInfo | null> | null;
  userManagementErrorMessage: Ref<string | null> = ref(null);
  constructor() {
    this.loader = this.refreshCurrentUser();
    this.loader.finally(() => {
      this.loader = null;
    });
  }
  private setErrorMessage(err: AxiosError) {
    if (err && err.response?.data?.message) {
      this.userManagementErrorMessage.value = err.response.data.message;
    } else {
      this.userManagementErrorMessage.value = 'Could not reach the server.';
    }
  }
  async waitForLoad(): Promise<void> {
    if (this.loader) {
      await this.loader;
    }
  }
  getCurrentUser(): Ref<UserInfo | null> {
    return readonly(this.userRef);
  }
  async refreshCurrentUser(): Promise<UserInfo | null> {
    try {
      const result = await axios.get<UserInfo>('/user/me');
      this.userRef.value = result.data;
      return result.data;
    } catch (e) {
      const axiosErr = e as AxiosError;
      if (axiosErr.response) this.userRef.value = null;
      return this.userRef.value;
    }
  }
  async doLogin(username: string, password: string): Promise<UserInfo | null> {
    if (username.length === 0 || password.length === 0) {
      this.userManagementErrorMessage.value =
        'Username and password are required.';
      return null;
    }
    try {
      const result = await axios.post<UserInfo>('/user/login', {
        username,
        password,
      });
      this.userRef.value = result.data;
      this.userManagementErrorMessage.value = null;
      return result.data;
    } catch (e) {
      this.setErrorMessage(e as AxiosError);
      return null;
    }
  }

  async doLogout(): Promise<boolean> {
    try {
      await axios.post('/user/logout');
      this.userRef.value = null;
      return true;
    } catch (e) {
      return false;
    }
  }
  async doSignup(username: string, password: string): Promise<boolean> {
    if (username.length === 0 || password.length === 0) {
      this.userManagementErrorMessage.value =
        'Username and password are required.';
      return false;
    }
    try {
      const result = await axios.post<UserInfo>('/user/signup', {
        username,
        password,
      });
      this.userManagementErrorMessage.value = null;
      return !!result.data.username;
    } catch (e) {
      this.setErrorMessage(e as AxiosError);
      return false;
    }
  }
}
