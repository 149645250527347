import { DI } from '@/di';
import { LocationSearchRequest } from '@/services/map-service/location-search/search-request';
import { SearchResult } from '@/services/map-service/location-search/search-result';
import { computed, Ref, ref } from 'vue';
import { LocationSearchService } from '@/services/map-service/location-search/location-search.interface';
import { MapDisplayInfoService } from '@/services/map-service/display-info/map-display-info.interface';

export class LocationSearchComponent<T extends SearchResult> {
  readonly searchService = DI.get<LocationSearchService<T>>(
    LocationSearchService,
  );
  readonly mapService = DI.get<MapDisplayInfoService>(MapDisplayInfoService);
  readonly results = ref<T[]>([]);
  readonly showResults = computed(
    () =>
      this.searchText.value.length > 0 &&
      this.results.value.length > 0 &&
      this.searching.value,
  );

  constructor(
    private readonly searchText: Ref<string>,
    private readonly searching: Ref<boolean>,
  ) {}

  async loadResults(isAuto: boolean): Promise<void> {
    const zoom = this.mapService.getMapZoom();
    const searchRequest: LocationSearchRequest = {
      input: this.searchText.value,
      isAutocomplete: isAuto,
      findCloseTo: this.mapService.getMapCenter(),
      mapZoom: zoom ? Math.floor(zoom) : undefined,
    };
    try {
      const unwrappedResults = await this.searchService.doLocationSearch(
        searchRequest,
      );
      this.results.value = ref(unwrappedResults).value;
    } catch (e) {
      this.results.value = [];
    }
  }

  loadFinalResults(): void {
    void this.loadResults(false);
  }

  resetResults(): void {
    this.results.value = [];
  }

  setup(): void {
    // For consistency
  }
}
