
import {
  computed,
  ComputedRef,
  defineComponent,
  inject,
  PropType,
  Ref,
  SetupContext,
  toRef,
} from 'vue';
import {
  TripCity,
  TripCityPoint,
  TripPointType,
} from '../../services/trips/trip';
import {
  CityPointAnnotator,
  CityPointFlattener,
  CitySpecialPointFinder,
} from '../../parts/city/city-compute';
import {
  normalizedCopyAndAppend,
  NormalizedList,
  normalizeList,
  rebuildFromNormalized,
} from '@/utils/normalized';
import PointList from '../utils/PointList.vue';
import PointListItem from '../utils/PointListItem.vue';
import CityPointTime from './CityPointTime.vue';
import PointModalContent from '@/components/trip-view/point-modal/PointModalContent.vue';
import OvernightModalContent from '@/components/trip-view/point-modal/OvernightModalContent.vue';
import PointTravelInfo from './PointTravelInfo.vue';
import ModalTrigger from '@/components/utils/modals/ModalTrigger.vue';
import { SearchResult } from '@/services/map-service/location-search/search-result';
import { DI } from '@/di';
import { TripApiService } from '@/services/trips/trip-service.interface';
import { MapDisplayInfoService } from '@/services/map-service/display-info/map-display-info.interface';

import {
  getOvernightIconMap,
  getOvernightBorderColors,
} from '../../utils/city-overnight-class-utils';
import { getPointListBottomBorderStyle } from '@/utils/misc-styles';
import { OneCityMapDisplay } from '@/parts/city/one-city-map-display';
import { CityRouter } from '@/parts/city/city-router';
import { hoursAndMinutes } from '@/parts/hour-minute-string';
import { TripIconSet } from '@/services/trips/trip-icon-set.interface';
import { CityDayListFinder } from '@/parts/city/day-listing';
import {
  PointTimeInfo,
  PointTimeInfoCalculator,
} from '@/parts/city/point-times';
import { DISPLAY_OPTION_KEY, TRIP_OPTION_KEY } from '@/parts/injection-keys';
import { displayTime, displayTimeRange } from '@/utils/time-fmt';
import { DateTime } from 'luxon';

type SpecificPoints = Pick<TripCity, 'enter_via' | 'exit_via' | 'stay_at'>;

interface LockableTripCityPoint extends TripCityPoint {
  locked?: boolean;
}

class OnePointSetup {
  readonly name: ComputedRef<string>;
  readonly iconClass: ComputedRef<string>;
  constructor(
    private readonly model: Ref<TripCity>,
    private readonly key: keyof SpecificPoints,
    private readonly service: TripApiService,
    private readonly ctx: SetupContext,
    private readonly iconService: TripIconSet,
    private readonly defaultType: TripPointType,
  ) {
    this.name = computed(() => model.value[key]?.name || '');
    this.iconClass = computed(() => {
      const point = model.value[key];
      const ty = point ? point.type : this.defaultType;
      return `${this.iconService.getTripIconClass(ty)} tcv-iconized`;
    });
  }
  change(newPoint: SearchResult) {
    const item = this.service.createPointFromSearch(newPoint);
    item.type = this.defaultType;
    this.set(item);
  }
  centerMap() {
    const point = this.model.value[this.key];
    if (point != null) {
      DI.get<MapDisplayInfoService>(MapDisplayInfoService).setMapCenter(point);
    }
  }
  set(point: TripCityPoint) {
    const clone = { ...this.model.value };
    clone[this.key] = point;
    this.ctx.emit('update:modelValue', clone);
  }
  boundSet = this.set.bind(this);
}

export default defineComponent({
  name: 'TripCityViewer',
  components: {
    PointList,
    PointListItem,
    CityPointTime,
    ModalTrigger,
    PointTravelInfo,
    PointModalContent,
    OvernightModalContent,
  },
  props: {
    modelValue: {
      type: Object as PropType<TripCity>,
      required: true,
    },
  },
  setup(props, ctx) {
    const service = DI.get<TripApiService>(TripApiService);
    const { emit } = ctx;
    const city = toRef(props, 'modelValue');

    const tripOpts = inject(TRIP_OPTION_KEY);
    const displayOpts = inject(DISPLAY_OPTION_KEY);

    const specialFinder = new CitySpecialPointFinder(city);
    const annotator = new CityPointAnnotator(city);
    const flattener = new CityPointFlattener(specialFinder, annotator);
    const dayLister = new CityDayListFinder(city, annotator);
    const router = new CityRouter(dayLister);
    const listManager = new OneCityMapDisplay(flattener, router);
    const timeCalculator = new PointTimeInfoCalculator(
      city,
      annotator,
      router.routeData.asRef(),
      tripOpts,
    );
    const icons = DI.get<TripIconSet>(TripIconSet);

    const singlePoints = {
      stay_at: new OnePointSetup(
        city,
        'stay_at',
        service,
        ctx,
        icons,
        TripPointType.HOTEL,
      ),
      enter_via: new OnePointSetup(
        city,
        'enter_via',
        service,
        ctx,
        icons,
        TripPointType.ARRIVAL,
      ),
      exit_via: new OnePointSetup(
        city,
        'exit_via',
        service,
        ctx,
        icons,
        TripPointType.AIRPORT,
      ),
    };

    const iconMap = computed(() =>
      getOvernightIconMap(
        city.value.nights,
        icons.getTripIconClass(city.value.stay_at?.type || TripPointType.HOTEL),
      ),
    );
    const borderMap = computed(() =>
      getOvernightBorderColors(city.value.nights),
    );

    const legOptions = computed(() => {
      const legOptMapping = router.routeData.value.days.flatMap((route, idx) =>
        route.details.map((detail) => ({
          id: detail.from.is_overnight ? `overnight-${idx}` : detail.from.id,
          options: detail.legOptions,
        })),
      );
      return normalizeList(legOptMapping);
    });

    function showPointTravelOptions(pointId: string): boolean {
      if (!legOptions.value.values[pointId]?.options.values()) return false;
      for (let opt of legOptions.value.values[pointId]?.options.values()) {
        if (opt.seconds > 0) return true;
      }
      return false;
    }

    const normalizedPoints = computed({
      get() {
        const listedPoints: LockableTripCityPoint[] =
          annotator.pointsWithSpecials.value.map((p) => ({ ...p.point }));
        let overnightNum = 0;
        listedPoints.forEach((p) => {
          if (p.is_overnight) {
            ++overnightNum;
            p.locked = true;
            p.name = `Day ${overnightNum}`;
          }
        });
        return normalizeList(listedPoints);
      },
      set(pnts: NormalizedList<TripCityPoint>) {
        const clone = { ...city.value };
        clone.points = rebuildFromNormalized(pnts);
        emit('update:modelValue', clone);
      },
    });

    const normalizedDays = computed(() => {
      const zipped = annotator.pointsWithSpecials.value.map((p) => ({
        day: p.day,
        id: p.point.id,
      }));
      return normalizeList(zipped);
    });

    function addSearched(point: SearchResult) {
      const item = service.createPointFromSearch(point);
      normalizedPoints.value = normalizedCopyAndAppend(
        normalizedPoints.value,
        item,
      );
    }

    function arrivalDayHasPoints(): boolean {
      return router.routeData.value.days[0]?.details.length > 1;
    }

    function getDayDurationString(day: number): string {
      const pointTime = dayLister.dayLists.value[day].reduce(
        (sum, p) => sum + p.minutes * 60,
        0,
      );
      const routeTime = router.routeData.value.days[day]?.totalSeconds || 0;

      if (pointTime + routeTime <= 0) return '';
      return hoursAndMinutes(pointTime + routeTime);
    }

    function getTimeOfDayString(pointId: string): string | undefined {
      const timeInfo = timeCalculator.timeInfo.value.values[pointId];
      if (!timeInfo) return undefined;
      return timeInfo.startTime.equals(timeInfo.endTime)
        ? displayTime(timeInfo.startTime)
        : displayTimeRange(timeInfo.startTime, timeInfo.endTime);
    }

    function getDayDisplayString(day: number): string {
      if (
        displayOpts?.value.useConcreteTime &&
        dayLister.dayLists.value[day].length > 0
      ) {
        const key = dayLister.dayLists.value[day][0].id;
        return getTimeOfDayString(key) || getDayDurationString(day);
      } else {
        return getDayDurationString(day);
      }
    }

    function getTimeInfo(
      pointId: string,
      k: keyof Omit<PointTimeInfo, 'id'>,
    ): DateTime | undefined {
      return timeCalculator.timeInfo.value.values[pointId]?.[k];
    }

    function setHoverPoint(point: string | TripCityPoint | null | undefined) {
      if (!point) return;
      listManager.setHighlight(typeof point === 'string' ? point : point.id);
    }

    function clearHoverPoint(point: string | TripCityPoint | null | undefined) {
      if (!point) return;
      listManager.clearHighlight(typeof point === 'string' ? point : point.id);
    }

    listManager.setup();

    return {
      specialFinder,
      annotator,
      flattener,
      router,
      listManager,
      normalizedPoints,
      normalizedDays,
      legOptions,
      showPointTravelOptions,
      addSearched,
      singlePoints,
      iconMap,
      borderMap,
      arrivalDayHasPoints,
      startBottomBorderStyle: computed(() =>
        getPointListBottomBorderStyle(borderMap.value['overnight-0']),
      ),
      getDayDurationString,
      getTimeOfDayString,
      getTimeInfo,
      getDayDisplayString,
      displayOpts,
      setHoverPoint,
      clearHoverPoint,
      defaultDayHour: tripOpts?.value?.dayStartHour,
      defaultDayMinute: tripOpts?.value?.dayStartMinute,
    };
  },
});
