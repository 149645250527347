
import {
  computed,
  defineComponent,
  onUnmounted,
  PropType,
  provide,
  ref,
  watch,
} from 'vue';
import { ManagedPointDisplay } from '@/parts/ManagedPointDisplay';
import { TripCity, TripDetail, TripOptions } from '@/services/trips/trip';
import { CITY_PIN_ICON_CLASS, CITY_PIN_Z_INDEX } from '@/icon-styling';
import {
  normalizedCopyAndAppend,
  NormalizedList,
  normalizeList,
  rebuildFromNormalized,
} from '@/utils/normalized';
import { SearchResult } from '@/services/map-service/location-search/search-result';
import CityModalContent from './city-modal/CityModalContent.vue';
import TripCityViewer from './TripCityViewer.vue';
import PointList from '../utils/PointList.vue';
import FaIconModalTrigger from '@/components/utils/modals/FaIconModalTrigger.vue';
import TripOptionConfig from '@/components/option-config/TripOptionConfig.vue';
import DisplayOptionConfig from '@/components/option-config/DisplayOptionConfig.vue';
import InlineDuration from '@/components/utils/InlineDuration.vue';
import ModalTrigger from '@/components/utils/modals/ModalTrigger.vue';
import CostDisplay from '@/components/utils/CostDisplay.vue';
import { DI } from '@/di';
import { TripApiService } from '@/services/trips/trip-service.interface';
import { PointSetDisplayType } from '@/map-display';
import { DisplayOptions } from '@/parts/DisplayOptions';
import { DISPLAY_OPTION_KEY } from '@/parts/injection-keys';

import { countTripNights } from '@/parts/night-counter';

import { normalizedCityStartDates } from '@/parts/city/city-start-dates';
import { DateTime } from 'luxon';
import { TotalCityCostCalculator } from '@/parts/TotalCityCostCalculator';
import { Debouncer } from '@/utils/Debouncer';

export default defineComponent({
  name: 'TripViewer',
  components: {
    CityModalContent,
    CostDisplay,
    DisplayOptionConfig,
    FaIconModalTrigger,
    InlineDuration,
    PointList,
    ModalTrigger,
    TripCityViewer,
    TripOptionConfig,
  },
  props: {
    modelValue: {
      type: Object as PropType<TripDetail>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const service = DI.get<TripApiService>(TripApiService);
    const collapsed = ref(false);

    const changeDebounce = new Debouncer(1000, (part: Partial<TripDetail>) => {
      const trip = { ...props.modelValue, ...part };
      emit('update:modelValue', trip);
    });

    onUnmounted(() => {
      changeDebounce.fireImmediately();
    });

    function scheduleNoteUpdate(event: InputEvent) {
      const notes = (event?.target as HTMLTextAreaElement).value;
      changeDebounce.startDebounce({ notes });
    }

    function scheduleCostUpdate(cost: number) {
      changeDebounce.startDebounce({ cost });
    }

    const displayOptions = ref(new DisplayOptions());
    provide(DISPLAY_OPTION_KEY, displayOptions);

    const display = new ManagedPointDisplay(
      computed(() => props.modelValue.cities),
      PointSetDisplayType.All,
    );
    display.iconClass = CITY_PIN_ICON_CLASS;
    display.zIndex = CITY_PIN_Z_INDEX;
    display.polyinfo = {
      color: '',
    };

    const nightTotal = computed(() => countTripNights(props.modelValue));
    const costTotal = computed(() => {
      const cityCostCalc = new TotalCityCostCalculator(props.modelValue.cities);
      return cityCostCalc.totalCost() + (props.modelValue.cost || 0);
    });

    const cityDateInfo = computed(() => {
      const start = props.modelValue.options?.startDate;
      if (!start) return undefined;
      else return normalizedCityStartDates(props.modelValue.cities, start);
    });

    const normalizedPoints = computed({
      get() {
        return normalizeList(props.modelValue.cities);
      },
      set(pnts: NormalizedList<TripCity>) {
        const trip = { ...props.modelValue };
        trip.cities = rebuildFromNormalized(pnts);
        trip.cities = trip.cities.map((city) => {
          const clone = { ...city };
          let overnights = 0;
          clone.points = city.points.filter((pnt) => {
            if (pnt.is_overnight) {
              ++overnights;
              return overnights <= city.nights;
            }
            return true;
          });
          const stay = clone.stay_at;
          if (stay) {
            clone.points.forEach((p) => {
              if (p.is_overnight) {
                p.lat = stay.lat;
                p.lng = stay.lng;
              }
            });
          }
          return clone;
        });
        emit('update:modelValue', trip);
      },
    });
    function addSearched(result: SearchResult) {
      const item = service.createCityFromSearch(result);
      normalizedPoints.value = normalizedCopyAndAppend(
        normalizedPoints.value,
        item,
      );
    }

    function emitBack() {
      emit('back');
    }

    function updateOptions(newOpts: TripOptions) {
      const clone = { ...props.modelValue };
      clone.options = newOpts;
      emit('update:modelValue', clone);
    }

    function generateNightCbObject(
      point: TripCity,
      callback: (point: TripCity) => void,
    ) {
      return {
        cb: (nights: number) => {
          callback({ ...point, nights });
        },
      };
    }

    function getCityNightsButtonText(cityId: string) {
      if (!displayOptions.value.useConcreteTime || !cityDateInfo.value)
        return undefined;
      else {
        const date = cityDateInfo.value.values[cityId].date;
        return date.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
      }
    }

    // TODO Remove or restrict this to something more reasonable
    // This doesn't really seem right, but testing had the full router view
    // text getting selected when removing the collapse, which also wasn't
    // great.
    watch(collapsed, () => {
      if (!collapsed.value) document.getSelection()?.removeAllRanges();
    });

    display.setup();

    return {
      emitBack,
      cityDateInfo,
      collapsed,
      display,
      displayOptions,
      normalizedPoints,
      nightTotal,
      costTotal,
      addSearched,
      updateOptions,
      generateNightCbObject,
      getCityNightsButtonText,
      scheduleNoteUpdate,
      scheduleCostUpdate,
      popupOpen: ref(false),
    };
  },
});
