import { computed, ComputedRef, Ref } from 'vue';
import { DisplayablePointOfInterest } from '@/map-display';
import { TripCity, TripCityPoint, TripPointType } from '@/services/trips/trip';
import { DI } from '@/di';
import { TripIconSet } from '@/services/trips/trip-icon-set.interface';
import {
  CITY_DAY_COLORS,
  CITY_DAY_HIGHLIGHTS,
  CITY_STAY_AT_PIN_COLOR,
  CITY_STAY_AT_PIN_HIGHLIGHT,
  CITY_VIA_PIN_COLOR,
  CITY_VIA_PIN_HIGHLIGHT,
} from '@/icon-styling';

type ManagedPoint = DisplayablePointOfInterest & TripCityPoint;
type PointArray = (DisplayablePointOfInterest & TripCityPoint)[];

export interface AnnotatedPoint<T> {
  day: number;
  point: T;
}

function convertPoint(
  point: TripCityPoint,
  type?: TripPointType,
  color?: string,
  highlightColor?: string,
): ManagedPoint;
function convertPoint(
  point: TripCityPoint | null,
  type?: TripPointType,
  color?: string,
  highlightColor?: string,
): ManagedPoint | null;
function convertPoint(
  point: TripCityPoint | null,
  type?: TripPointType,
  color?: string,
  highlightColor?: string,
): ManagedPoint | null {
  if (point == null) return null;
  return {
    ...point,
    displayOptions: {
      iconClass: DI.get<TripIconSet>(TripIconSet).getTripIconClass(
        type || point.type,
      ),
      color: color,
      highlightColor: highlightColor,
    },
  };
}

export class CitySpecialPointFinder {
  readonly enterExitPoints: ComputedRef<PointArray>;
  readonly enterPoint: ComputedRef<ManagedPoint | null>;
  readonly exitPoint: ComputedRef<ManagedPoint | null>;
  readonly stayPoint: ComputedRef<ManagedPoint | null>;
  readonly allSpecials: ComputedRef<PointArray>;

  constructor(private readonly city: Ref<TripCity>) {
    this.enterPoint = computed(() => {
      return convertPoint(
        this.city.value.enter_via,
        this.city.value.enter_via?.type || TripPointType.ARRIVAL,
        CITY_VIA_PIN_COLOR,
        CITY_VIA_PIN_HIGHLIGHT,
      );
    });
    this.exitPoint = computed(() => {
      return convertPoint(
        this.city.value.exit_via,
        this.city.value.exit_via?.type || TripPointType.AIRPORT,
        CITY_VIA_PIN_COLOR,
        CITY_VIA_PIN_HIGHLIGHT,
      );
    });
    this.stayPoint = computed(() => {
      return convertPoint(
        this.city.value.stay_at,
        this.city.value.stay_at?.type || TripPointType.HOTEL,
        CITY_STAY_AT_PIN_COLOR,
        CITY_STAY_AT_PIN_HIGHLIGHT,
      );
    });
    this.enterExitPoints = computed(() => {
      const combinedPoints = [];
      if (this.enterPoint.value) combinedPoints.push(this.enterPoint.value);
      if (this.exitPoint.value) combinedPoints.push(this.exitPoint.value);
      return combinedPoints;
    });
    this.allSpecials = computed(() => {
      const cp = this.enterExitPoints.value.map((p) => p);
      if (this.stayPoint.value) {
        if (this.city.value.enter_via) {
          cp.splice(1, 0, this.stayPoint.value);
        } else {
          cp.splice(0, 0, this.stayPoint.value);
        }
      }
      return cp;
    });
  }
}

export class CityPointAnnotator {
  readonly pointsWithoutSpecials: ComputedRef<AnnotatedPoint<TripCityPoint>[]>;
  readonly pointsWithSpecials: ComputedRef<AnnotatedPoint<TripCityPoint>[]>;

  constructor(private readonly city: Ref<TripCity>) {
    this.pointsWithSpecials = computed(() => this.getPointsWithSpecials());
    this.pointsWithoutSpecials = computed(() =>
      this.getPointsWithoutSpecials(),
    );
  }

  getWithVerifiedOvernights(): TripCityPoint[] {
    const stay_at: TripCityPoint | null = this.city.value.stay_at;

    let overnightCount = 0;
    const fixedOvernights = this.city.value.points.filter((p) => {
      if (p.is_overnight) {
        if (overnightCount >= this.city.value.nights) {
          return false;
        }
        ++overnightCount;
      }
      return true;
    });

    for (; overnightCount < this.city.value.nights; ++overnightCount) {
      fixedOvernights.push({
        type: stay_at?.type || TripPointType.OTHER,
        lat: stay_at ? stay_at.lat : this.city.value.lat,
        lng: stay_at ? stay_at.lng : this.city.value.lng,
        name: stay_at ? stay_at.name : this.city.value.name,
        place_id: stay_at ? stay_at.place_id : this.city.value.place_id,
        minutes: 0,
        is_overnight: true,
        notes: '',
        outgoing_travel: null,
        id: `overnight-${overnightCount + 1}`,
      });
    }

    return fixedOvernights;
  }

  getAnnotatedPoints(): AnnotatedPoint<TripCityPoint>[] {
    let day = 0;
    return this.getWithVerifiedOvernights().map((elem) => {
      if (elem.is_overnight) ++day;
      return { day, point: elem };
    });
  }

  getPointsWithoutSpecials(): AnnotatedPoint<TripCityPoint>[] {
    return this.getAnnotatedPoints().filter(
      (p) => !p.point.is_overnight,
    ) as AnnotatedPoint<TripCityPoint>[];
  }

  getPointsWithSpecials(): AnnotatedPoint<TripCityPoint>[] {
    return this.city.value.stay_at
      ? this.getAnnotatedPoints()
      : this.getPointsWithoutSpecials();
  }
}

export class CityPointFlattener {
  readonly flatCityPoints: ComputedRef<PointArray>;

  constructor(
    specialFinder: CitySpecialPointFinder,
    annotator: CityPointAnnotator,
  ) {
    this.flatCityPoints = computed(() => {
      const noSpecials = annotator.pointsWithoutSpecials.value.map((p) =>
        convertPoint(
          p.point,
          p.point.type,
          CITY_DAY_COLORS[p.day % CITY_DAY_COLORS.length],
          CITY_DAY_HIGHLIGHTS[p.day % CITY_DAY_HIGHLIGHTS.length],
        ),
      );

      return noSpecials.concat(specialFinder.allSpecials.value);
    });
  }
}
