
import { computed, defineComponent, PropType } from 'vue';
import HiddenFinder from '@/components/search/HiddenFinder.vue';
import PointListItem from './PointListItem.vue';
import draggable from 'vuedraggable';
import { SearchResult } from '@/services/map-service/location-search/search-result';
import { DI } from '@/di';
import { MapDisplayInfoService } from '@/services/map-service/display-info/map-display-info.interface';
import { DisplayablePointOfInterest } from '@/map-display';
import { NormalizedList, rebuildFromNormalized } from '@/utils/normalized';
import { getPointListBottomBorderStyle } from '@/utils/misc-styles';

let generatedGroup = 0;

export default defineComponent({
  name: 'PointList',
  components: {
    HiddenFinder,
    PointListItem,
    draggable,
  },
  props: {
    modelValue: {
      type: Object as PropType<NormalizedList<DisplayablePointOfInterest>>,
      required: true,
    },
    idIconMap: {
      type: Object as PropType<Record<string, string>>,
      required: false,
    },
    idBorderMap: {
      type: Object as PropType<Record<string, string>>,
      required: false,
    },
    dragGroup: String,
    itemClass: String,
    padAll: Boolean,
  },
  setup(props, { emit }) {
    const draggableList = computed({
      get() {
        return rebuildFromNormalized(props.modelValue);
      },
      set(list: DisplayablePointOfInterest[]) {
        const newOrder = list.map((item) => item.id);
        let clone = { ...props.modelValue };
        clone.ordering = newOrder;
        emit('update:modelValue', clone);
      },
    });

    const actualDragGroup =
      props.dragGroup != undefined ? props.dragGroup : `${generatedGroup++}`;

    function centerOnItem(id: string) {
      const point = props.modelValue.values[id];
      if (point)
        DI.get<MapDisplayInfoService>(MapDisplayInfoService).setMapCenter(
          point,
        );
    }

    function updatePoint(elemId: string, newPoint: DisplayablePointOfInterest) {
      let clone = { ...props.modelValue };
      clone.values[elemId] = newPoint;
      emit('update:modelValue', clone);
    }

    function addPoint(res: SearchResult) {
      emit('addSearched', res);
    }

    function deletePoint(id: string) {
      let clone = { ...props.modelValue };
      delete clone.values[id];
      clone.ordering = clone.ordering.filter((pointId) => pointId != id);
      emit('update:modelValue', clone);
    }

    function changePoint(id: string, result: SearchResult) {
      let clone = { ...props.modelValue };
      clone.values[id].lat = result.location.lat;
      clone.values[id].lng = result.location.lng;
      clone.values[id].name = result.name;
      emit('update:modelValue', clone);
    }

    function getItemBorder(id: string): string {
      if (!props.idBorderMap || !props.idBorderMap[id]) return '';
      const color = props.idBorderMap[id];
      return getPointListBottomBorderStyle(color);
    }

    return {
      actualDragGroup,
      centerOnItem,
      updatePoint,
      addPoint,
      deletePoint,
      changePoint,
      draggableList,
      getItemBorder,
    };
  },
});
