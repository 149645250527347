import { injectable } from 'inversify';
import axios from 'axios';
import { Point } from 'geojson';
import { LocationEngineInfo } from './engine-info';
import { LocationSearchService } from './location-search.interface';
import { LocationSearchRequest } from './search-request';
import { SearchResult } from './search-result';

const MAX_LOCATION_PARTS = 2;

interface PhotonFeatureProps {
  name: string;
  city?: string;
  countrycode?: string;
  osm_value?: string;
  street?: string;
  osm_id: number;
}

type PhotonCollection = GeoJSON.FeatureCollection<Point, PhotonFeatureProps>;

function titleCaseValue(value: string): string {
  const spaced = value.replace('_', ' ');
  const split = spaced.split(' ');
  return split
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

const BAD_VALUES = [
  'primary',
  'secondary',
  'tertiary',
  'city',
  'state',
  'street',
  'administrative',
];

function isValueUsable(value: string): boolean {
  return BAD_VALUES.indexOf(value) == -1;
}

@injectable()
export class PhotonSearchService
  implements LocationSearchService<SearchResult>
{
  getEngineSpecification(): LocationEngineInfo {
    return {
      autocompletionLimits: null,
    };
  }
  async doLocationSearch(
    search: LocationSearchRequest,
  ): Promise<SearchResult[]> {
    let llPart = '';
    if (search.findCloseTo) {
      const zoomScale = search.mapZoom ? `&zoom=${search.mapZoom}` : '';
      llPart = `&lat=${search.findCloseTo.lat}&lon=${search.findCloseTo.lng}${zoomScale}`;
    }
    const queryPart = `q=${search.input.toLowerCase()}`;
    const lang = `&lang=en`;
    const url = `https://photon.komoot.io/api/?${queryPart}${llPart}${lang}`;
    const result = await axios.get<PhotonCollection>(url, {
      withCredentials: false,
    });
    return this.convertResultGeoJson(result.data);
  }
  convertResultGeoJson(results: PhotonCollection): SearchResult[] {
    const featProps = results.features.map((feat) => feat.properties);
    return featProps.map((feat, idx) => {
      const name = feat.name;
      const locationParts: string[] = [];
      if (feat.street) locationParts.push(feat.street);
      if (feat.city) locationParts.push(feat.city);
      if (feat.countrycode) locationParts.push(feat.countrycode);
      const locationDrilldown = locationParts
        .filter((_, idx) => idx < MAX_LOCATION_PARTS)
        .join(', ');

      const type =
        feat.osm_value && isValueUsable(feat.osm_value)
          ? titleCaseValue(feat.osm_value)
          : null;
      const typePart = type ? `${type} ${feat.street ? 'on' : 'in'} ` : '';

      const fullDetail = typePart + locationDrilldown;

      return {
        name,
        detail: fullDetail,
        location: {
          lng: results.features[idx].geometry.coordinates[0],
          lat: results.features[idx].geometry.coordinates[1],
        },
        place_id: `${feat.osm_id}`,
      };
    });
  }
}
