
import { DisplayOptions } from '@/parts/DisplayOptions';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'DisplayOptionConfig',
  props: {
    modelValue: {
      type: Object as PropType<DisplayOptions>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const showTime = computed({
      get() {
        return props.modelValue.useConcreteTime;
      },
      set(useConcreteTime) {
        const cl = { ...props.modelValue, useConcreteTime };
        emit('update:modelValue', cl);
      },
    });

    return { showTime };
  },
});
