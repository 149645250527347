import { MapTileService } from './map-tile.interface';
import L from 'leaflet';
import { injectable } from 'inversify';
import { MapVectorJsonService } from './map-vector-json.interface';

@injectable()
export class GeoapifyTileService
  implements MapTileService, MapVectorJsonService
{
  TILE_THEME = 'osm-bright';
  isMapboxVectorStyle = true;
  getStyleUrl(): string {
    const apiKey = `apiKey=${process.env.VUE_APP_GEOAPIFY_MAP_KEY}`;
    return `https://maps.geoapify.com/v1/styles/${this.TILE_THEME}/style.json?${apiKey}`;
  }
  buildOpenLayersTileUrl(): string {
    throw new Error('Method not implemented.');
  }
  getOpenLayersAttributions(): string | string[] {
    throw new Error('Method not implemented.');
  }

  GEOAPIFY_RASTER_URL_BASE = 'https://maps.geoapify.com/v1/tile';
  buildLeafletTileUrl(): string {
    const retinaStr = L.Browser.retina ? '@2x' : '';
    const extension = `${retinaStr}.png`;
    const apiKey = `apiKey=${process.env.VUE_APP_GEOAPIFY_MAP_KEY}`;

    const fullString = `${this.GEOAPIFY_RASTER_URL_BASE}/${this.TILE_THEME}/{z}/{x}/{y}${extension}?${apiKey}`;
    return fullString;
  }
  buildOptions(): Record<string, unknown> {
    return {
      attribution:
        'Powered by <a href="https://www.geoapify.com/" target="_blank">Geoapify</a> | © OpenStreetMap <a href="https://www.openstreetmap.org/copyright" target="_blank">contributors</a>',
      maxZoom: 20,
    };
  }
}
