import { injectable } from 'inversify';
import { MapVectorJsonService } from './map-vector-json.interface';
@injectable()
export class LocalOpenMapTilesVectorJsonService
  implements MapVectorJsonService
{
  isMapboxVectorStyle = false;
  getStyleUrl(): string {
    return '';
  }
  buildOpenLayersTileUrl(): string {
    // TODO use base url or environment variable or something
    return `http://${window.location.hostname}:8090/tiles/{z}/{x}/{y}.pbf`;
  }
  getOpenLayersAttributions(): string | string[] {
    return [
      '© <a href="https://www.openstreetmap.org/copyright">' +
        'OpenStreetMap contributors</a>',
    ];
  }
}
