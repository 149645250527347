import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "left-hud ed-scrollable" }
const _hoisted_2 = { class: "d-flex flex-column align-items-center" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "mt-2 mb-0",
  id: "ts-horiz-rule"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_trip_creator = _resolveComponent("trip-creator")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trips, (trip) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "trip-item primary-clickable",
          key: trip._id,
          onClick: ($event: any) => (_ctx.triggerNav(trip))
        }, _toDisplayString(trip.name), 9, _hoisted_3))
      }), 128)),
      (_ctx.trips.length > 0)
        ? (_openBlock(), _createElementBlock("hr", _hoisted_4))
        : _createCommentVNode("", true),
      _createVNode(_component_trip_creator, { onNewTrip: _ctx.processNewTrip }, null, 8, ["onNewTrip"])
    ])
  ]))
}