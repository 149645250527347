import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "hidden-finder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_location_finder = _resolveComponent("location-finder")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hidden)
      ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          class: "bi-plus primary-clickable",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hidden=false))
        }))
      : (_openBlock(), _createBlock(_component_location_finder, {
          key: 1,
          class: "hidden-finder-actual w-100",
          onLocationChosen: _ctx.locationChosen,
          "clear-on-chosen": _ctx.clearOnChosen,
          "take-mount-focus": true,
          "focus-on-chosen": _ctx.focusOnChosen,
          onUnsearch: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hidden=true))
        }, null, 8, ["onLocationChosen", "clear-on-chosen", "focus-on-chosen"]))
  ]))
}