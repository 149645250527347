import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({bouncing: _ctx.animationRunning}),
    onAnimationiteration: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.animationIterationComplete && _ctx.animationIterationComplete(...args))),
    onAnimationend: _cache[1] || (_cache[1] = ($event: any) => (_ctx.animationRunning=false))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 34))
}