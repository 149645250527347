import { MapPoint } from '@/map-display';
import axios from 'axios';
import { injectable } from 'inversify';
import {
  TimezoneIdentifier,
  TimezoneLookupService,
} from './timezone-lookup.interface';

@injectable()
export class ServerTzLookupService implements TimezoneLookupService {
  async getTimezone(point: MapPoint): Promise<TimezoneIdentifier> {
    const resp = await axios.get(`timezone/lookup/${point.lat}/${point.lng}`);
    return resp.data.tz;
  }
}
