import { DI } from '@/di';
import { MapPoint } from '@/map-display';
import {
  DirectionRouterService,
  RouteLeg,
} from '@/services/map-service/routing/direction-router.interface';
import { RouteType } from '@/services/map-service/routing/route-type';
import { TripCityPoint } from '@/services/trips/trip';
import { AsyncRef } from '@/utils/async-ref';
import { CityDayListFinder } from './day-listing';

export type RouteOptionMap = Map<RouteType, RouteLeg>;

export interface IntraCityLeg {
  legOptions: RouteOptionMap;
  from: TripCityPoint;
  chosen: RouteLeg;
}

export interface IntraCityRoute {
  fullRoute: MapPoint[];
  details: IntraCityLeg[];
  totalSeconds: number;
}

export interface RoutedCityData {
  days: IntraCityRoute[];
}

export class CityRouter {
  readonly routeData: AsyncRef<RoutedCityData>;
  private readonly routerService: DirectionRouterService = DI.get(
    DirectionRouterService,
  );
  constructor(private readonly dayLister: CityDayListFinder) {
    this.routeData = new AsyncRef(
      [this.dayLister.dayLists],
      () => this.computeSync(),
      () => this.computeAsync(),
      { deep: true },
    );
  }

  protected computeSync(): RoutedCityData {
    const days = this.dayLister.dayLists.value.map((dayPoints) => {
      const fullRoute = dayPoints;
      const legs: IntraCityLeg[] = this.arrayToLegsSync(fullRoute);
      return {
        fullRoute,
        details: legs,
        totalSeconds: -1,
      };
    });

    return {
      days,
    };
  }
  protected async computeAsync(): Promise<RoutedCityData> {
    const days = this.dayLister.dayLists.value;
    const routePromises = days.map((day) =>
      this.routerService.getRoutedPoints(day),
    );

    const routeResults = await Promise.allSettled(routePromises);

    const routes = routeResults.map((promisedRoute, i) => {
      if (promisedRoute.status === 'fulfilled') {
        return this.legsToRoute(
          (promisedRoute as PromiseFulfilledResult<RouteLeg[][]>).value,
          days[i],
        );
      } else {
        return {
          fullRoute: days[i],
          details: this.arrayToLegsSync(days[i]),
          totalSeconds: -1,
        };
      }
    });

    return {
      days: routes,
    };
  }

  legsToRoute(
    routeOptions: RouteLeg[][],
    points: TripCityPoint[],
  ): IntraCityRoute {
    if (points.length == 0) {
      return { details: [], fullRoute: [], totalSeconds: 0 };
    }
    if (points.length === 1) {
      const opt = {
        points: [points[0]],
        seconds: -1,
        type: RouteType.WALK,
      };
      return {
        details: [
          {
            from: points[0],
            legOptions: singleLegOptionMap(opt),
            chosen: opt,
          },
        ],
        fullRoute: points,
        totalSeconds: 0,
      };
    }

    if (routeOptions.find((route) => route.length != points.length - 1))
      throw Error('All routes should match the number of points');

    const details: IntraCityLeg[] = routeOptions[0].map((leg, i) => {
      const mapping: RouteOptionMap = new Map();
      routeOptions.forEach((opt) => mapping.set(opt[i].type, opt[i]));
      const out = points[i].outgoing_travel;
      const preferred = out ? mapping.get(out) : undefined;
      const walk = mapping.get(RouteType.WALK);
      const car = mapping.get(RouteType.CAR);

      const chosen = this.pickFirstValidLeg([preferred, walk, car]) || {
        points: [points[i]],
        seconds: -1,
        type: RouteType.WALK,
      };

      return {
        legOptions: mapping,
        from: points[i],
        chosen,
      };
    });

    let totalSeconds = 0;
    const fullRoute = details.flatMap((leg, i) => {
      const route = leg.chosen;
      totalSeconds += route.seconds > 0 ? route.seconds : 0;
      return i < details.length - 1 ? route.points.slice(0, -1) : route.points;
    });
    return { details, fullRoute, totalSeconds };
  }

  pickFirstValidLeg(routes: (RouteLeg | undefined)[]): RouteLeg | undefined {
    for (const routeOpt of routes) {
      if (routeOpt && routeOpt.seconds >= 0) return routeOpt;
    }
    return undefined;
  }

  arrayToLegsSync(pointArr: TripCityPoint[]): IntraCityLeg[] {
    return pointArr.map((onePoint, idx) => {
      const leg =
        idx === 0
          ? {
              points: [onePoint],
              seconds: -1,
              type: RouteType.WALK,
            }
          : {
              points: [pointArr[idx - 1], onePoint],
              seconds: -1,
              type: RouteType.WALK,
            };
      return {
        from: onePoint,
        legOptions: singleLegOptionMap(leg),
        chosen: leg,
      };
    });
  }
}

function singleLegOptionMap(leg: RouteLeg): RouteOptionMap {
  const map: RouteOptionMap = new Map();
  map.set(leg.type, leg);
  return map;
}
