
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  toRef,
  watch,
} from 'vue';
import { SearchResult } from '@/services/map-service/location-search/search-result';
import { LocationSearchComponent } from '../../parts/LocationSearchComponent';
import { ResultDisplayManager } from '../../parts/ResultDisplayManager';
import EdPopup from '@/components/utils/EdPopup.vue';

export default defineComponent({
  name: 'LocationFinder',
  components: {
    EdPopup,
  },
  props: {
    clearOnChosen: Boolean,
    focusOnChosen: Boolean,
    location: String,
    takeMountFocus: Boolean,
    fallbackInfoText: String,
    hoverInfoText: String,
  },
  setup(props, { emit }) {
    const locationName = ref(props.location || '');
    const nameAtSearchStart = ref(locationName.value);
    const selected = ref(false);
    const searchBox = ref<HTMLInputElement | null>(null);
    const resultDiv = ref<HTMLDivElement | null>(null);
    const wasHidden = ref(true);
    const editing = computed(
      () =>
        (selected.value || locationName.value.length == 0) && !wasHidden.value,
    );

    const searchComponent = new LocationSearchComponent(locationName, editing);

    const nameMismatch = computed(
      () => !editing.value && nameAtSearchStart.value != locationName.value,
    );

    const showPopup = computed(
      () => searchComponent.showResults.value && !wasHidden.value,
    );
    const displayedResults = computed(() =>
      searchComponent.showResults.value ? searchComponent.results.value : [],
    );

    const resultDisplay = new ResultDisplayManager(
      displayedResults,
      toRef(props, 'focusOnChosen'),
    );
    function itemClicked(i: number, result: SearchResult) {
      if (props.focusOnChosen) resultDisplay.focusMapOnResult(i);
      selected.value = false;
      emit('locationChosen', result);

      locationName.value = props.clearOnChosen ? '' : result.name;
      searchComponent.resetResults();
      nameAtSearchStart.value = locationName.value;
    }

    function swapToSearch() {
      wasHidden.value = false;
      if (!selected.value) {
        selected.value = true;
      }
      nextTick(() => searchBox.value?.focus());
    }

    function maybeUnsearch(evt: FocusEvent) {
      // Never unsearch when displaying tooltip
      if (showPopup.value) return;
      if (
        !evt.relatedTarget ||
        !resultDiv.value?.contains(evt.relatedTarget as Node)
      ) {
        selected.value = false;
        if (locationName.value.length == 0)
          locationName.value = nameAtSearchStart.value;
        emit('unsearch');
      }
    }

    watch(locationName, () => {
      if (locationName.value.length == 0) {
        searchComponent.resetResults();
      }
    });

    watch(toRef(props, 'location'), (newLoc) => {
      if (newLoc) {
        locationName.value = newLoc;
        nameAtSearchStart.value = newLoc;
      }
    });

    if (props.takeMountFocus)
      onMounted(() => {
        swapToSearch();
      });

    searchComponent.setup();
    resultDisplay.setup();
    return {
      swapToSearch,
      maybeUnsearch,
      selected,
      locationName,
      searchBox,
      resultDiv,
      editing,
      results: searchComponent.results,
      showResults: searchComponent.showResults,
      displayedResults,
      loadFinalResults: () => searchComponent.loadFinalResults(),
      itemClicked,
      setHighlight: (i: number) => resultDisplay.setHighlight(i),
      removeHighlight: (i: number) => resultDisplay.removeHighlight(i),
      searchComponent,
      showPopup,
      wasHidden,
      nameMismatch,
      nameAtSearchStart,
    };
  },
});
