
import 'leaflet/dist/leaflet.css';
import { defineComponent, onMounted, onUnmounted, Ref, shallowRef } from 'vue';
import L, { Map } from 'leaflet';
import { DI } from '@/di';
import { MapTileService } from '@/services/map-service/map-tile.interface';
import { PointSetCollapse } from '../../parts/PointSetCollapse';
import { PolylineFinder } from '../../parts/PolylineFinder';
import { MapDisplayInfoService } from '@/services/map-service/display-info/map-display-info.interface';
import { LeafletMapDisplayInfoService } from '@/services/map-service/display-info/leaflet-map-display-info.service';
import MapMarker from './MapMarker.vue';
import MapPolyline from './MapPolyline.vue';

function newMap(tileService: MapTileService): Map {
  let map = L.map('leaflet-map', { zoomControl: false });
  map.setView([51.5073219, -0.1276474], 13);
  L.tileLayer(
    tileService.buildLeafletTileUrl(),
    tileService.buildOptions(),
  ).addTo(map);

  let zoomCtrl = new L.Control.Zoom({ position: 'bottomleft' });
  zoomCtrl.addTo(map);

  return map;
}

export default defineComponent({
  name: 'LeafletMap',
  components: {
    MapMarker,
    MapPolyline,
  },
  setup() {
    const tileService = DI.get<MapTileService>(MapTileService);
    const displayInfoService = (function () {
      const raw = DI.get<MapDisplayInfoService>(MapDisplayInfoService);
      if (raw instanceof LeafletMapDisplayInfoService) return raw;
      return undefined;
    })();
    let map: Ref<Map | null> = shallowRef(null);
    //let pointDisplay: PointSetDisplay;
    let collapser = new PointSetCollapse();
    let polyFinder = new PolylineFinder();

    const doPostMountInit = () => {
      map.value = newMap(tileService);
      //pointDisplay = new PointSetDisplay(map);
      //pointDisplay.setup();

      displayInfoService?.setMap(map.value);
    };

    function destroyMap() {
      if (map.value) {
        map.value.off();
        map.value.remove();
      }
      displayInfoService?.setMap(undefined);
    }

    onMounted(doPostMountInit);
    onUnmounted(destroyMap);
    return {
      markerPoints: collapser.points,
      polylines: polyFinder.lines,
      map,
      collapser,
      polyFinder,
    };
  },
});
