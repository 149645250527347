/* eslint-disable no-console */
import { injectable } from 'inversify';
import { LoggerService } from './logger.interface';

@injectable()
export class ConsoleLogger implements LoggerService {
  async error(msg: unknown): Promise<void> {
    console.error(msg);
  }
  async warn(msg: unknown): Promise<void> {
    console.warn(msg);
  }
  async log(msg: unknown): Promise<void> {
    console.log(msg);
  }
}
