
import { MapPoint, MapMarkerProps } from '@/map-display';
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  ref,
  shallowRef,
  toRefs,
  watch,
} from 'vue';
import Map from 'ol/Map';
import Overlay from 'ol/Overlay';
import { fromLonLat } from 'ol/proj';
import { BASE_PIN_COLOR } from '@/icon-styling';
import BounceAnimation from '@/components/utils/BounceAnimation.vue';

export default defineComponent({
  name: 'OpenLayerMarker',
  components: {
    BounceAnimation,
  },
  props: {
    point: {
      type: Object as PropType<MapPoint>,
      required: true,
    },
    color: {
      type: String,
    },
    highlightColor: {
      type: String,
    },
    iconClass: {
      type: String,
    },
    zIndex: {
      type: Number,
    },
    hoverText: {
      type: String,
    },
    isHighlighted: {
      type: Boolean,
    },
    map: {
      type: Object as PropType<Map>,
      required: true,
    },
  },
  render() {
    return [];
  },
  setup(props: MapMarkerProps & { map: Map }) {
    const { point, iconClass } = toRefs(props);
    const marker = ref<HTMLDivElement | undefined>(undefined);
    const overlay = shallowRef<Overlay>(
      new Overlay({
        stopEvent: false,
        positioning: 'center-center',
      }),
    );
    const elementSet = ref(false);

    const styleString = computed(() => {
      const propColor = props.isHighlighted
        ? props.highlightColor || props.color
        : props.color;
      return propColor && propColor.length > 0
        ? `background-color:${propColor}`
        : `background-color:${BASE_PIN_COLOR}`;
    });
    const defaultedIconClasses = computed(() => {
      const cls =
        iconClass?.value && iconClass.value.length > 0
          ? [iconClass.value]
          : ['bi-circle-fill'];
      return cls;
    });

    function updateOverlay() {
      overlay.value.setPosition(fromLonLat([point.value.lng, point.value.lat]));
    }

    onMounted(() => {
      if (marker.value && !elementSet.value) {
        overlay.value.setElement(marker.value);
        props.map.addOverlay(overlay.value);
        elementSet.value = true;
      }
      updateOverlay();
    });

    onUnmounted(() => {
      if (elementSet.value) props.map.removeOverlay(overlay.value);
    });

    watch([point], () => updateOverlay(), { deep: true });

    return {
      marker,
      overlay,
      styleString,
      defaultedIconClasses,
      elementSet,
    };
  },
});
