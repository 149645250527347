export class Debouncer<T> {
  private timeout?: number;
  private value?: T;
  constructor(
    private debounceWait: number,
    private callback: (value: T) => unknown,
    private combineDebounced?: (original: T, incoming: T) => T,
  ) {}

  startDebounce(value: T): void {
    if (this.timeout !== undefined && this.combineDebounced)
      this.value = this.combineDebounced(this.value as T, value);
    else this.value = value;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.timeout = undefined;
      this.callback(this.value as T);
    }, this.debounceWait) as unknown as number;
  }

  fireImmediately(): void {
    if (this.timeout !== undefined) this.callback(this.value as T);
  }
}
