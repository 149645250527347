import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bounce_animation = _resolveComponent("bounce-animation")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", {
      class: _normalizeClass(["ol-marker", {highlighted: _ctx.isHighlighted}]),
      ref: "marker",
      title: _ctx.hoverText
    }, [
      _createVNode(_component_bounce_animation, { animate: _ctx.isHighlighted }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "marker-pin ol-pin",
            style: _normalizeStyle(_ctx.styleString)
          }, null, 4),
          _createElementVNode("i", {
            class: _normalizeClass(["ol-map-icon", _ctx.defaultedIconClasses])
          }, null, 2)
        ]),
        _: 1
      }, 8, ["animate"])
    ], 10, _hoisted_1)
  ]))
}