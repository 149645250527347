
import { computed, defineComponent, provide, ref, toRef, watch } from 'vue';
import { DI } from '@/di';
import { TripDetail, TripOptions } from '@/services/trips/trip';
import {
  TripDetailError,
  TripApiService,
} from '@/services/trips/trip-service.interface';
import TripViewer from '@/components/trip-view/TripViewer.vue';
import { TRIP_OPTION_KEY } from '@/parts/injection-keys';
import { useRouter } from 'vue-router';

function defaultOptions(): TripOptions {
  return {
    dayStartHour: 9,
    dayStartMinute: 0,
  };
}

export default defineComponent({
  name: 'TripManager',
  components: {
    TripViewer,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const tripService = DI.get<TripApiService>(TripApiService);
    const selectedTrip = ref<TripDetail | null>(null);

    const options = computed(() => selectedTrip.value?.options);
    const router = useRouter();

    const hadSendError = ref(false);
    const noActivity = ref(false);
    // 2 minutes
    let timeout: number | undefined = undefined;

    function resetActivity() {
      if (timeout !== undefined) clearTimeout(timeout);
      timeout = setTimeout(() => {
        noActivity.value = true;
      }, 1000 * 60 * 5) as unknown as number;
      noActivity.value = false;
    }

    resetActivity();

    function goToSelection() {
      router.push({ name: 'TripSelect' });
    }

    provide(TRIP_OPTION_KEY, options);

    async function setSelection(tripId: string) {
      let detail = await tripService.getTripDetail(tripId);
      if (detail === TripDetailError.NOT_FOUND) goToSelection();
      else if (detail === TripDetailError.UNKNOWN) {
        setTimeout(() => {
          setSelection(tripId);
        }, 1000);
      } else {
        detail.options = { ...defaultOptions(), ...detail.options };
        selectedTrip.value = detail;
      }
    }

    setSelection(props.id);

    watch(toRef(props, 'id'), () => setSelection(props.id));

    async function doTripUpdate(trip: TripDetail) {
      selectedTrip.value = trip;
      const result = await tripService.updateTrip(trip);
      hadSendError.value = !result;
      resetActivity();
    }

    return {
      selectedTrip,
      goToSelection,
      doTripUpdate,
      options,
      hadSendError,
      noActivity,
      resetActivity,
    };
  },
});
