
import { SettableProps } from '@/parts/SettableProps';
import { TripCityPoint } from '@/services/trips/trip';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      type: Object as PropType<TripCityPoint>,
      required: true,
    },
    defaultHour: Number,
    defaultMinute: Number,
  },
  setup(props, { emit }) {
    const settable = new SettableProps(
      () => {
        const copy = { ...props.modelValue };
        if (copy.start_hour === undefined) copy.start_hour = props.defaultHour;
        if (copy.start_minute === undefined)
          copy.start_minute = props.defaultMinute;
        return copy;
      },
      emit,
      'update:modelValue',
    );
    const hour = settable.getPropRef('start_hour', parseInt);
    const minute = settable.getPropRef('start_minute', parseInt);
    function* timeOptions(maxExclusive: number) {
      let i = 0;
      for (; i < maxExclusive; ++i) {
        yield { num: i, str: String(i).padStart(2, '0') };
      }
    }
    const hourOptions = Array.from(timeOptions(24));
    const minuteOptions = Array.from(timeOptions(60));

    return {
      hour,
      minute,
      hourOptions,
      minuteOptions,
    };
  },
});
