
import { DI } from '@/di';
import { DirectionRouterService } from '@/services/map-service/routing/direction-router.interface';
import L, { Polyline, Map as LeafletMap } from 'leaflet';
import {
  computed,
  ComputedRef,
  defineComponent,
  onUnmounted,
  PropType,
  ref,
  toRefs,
  watch,
} from 'vue';
import { MapPoint, PolyInfoDisplayOptions } from '@/map-display';

export default defineComponent({
  name: 'MapPolyline',
  props: {
    points: {
      type: Array as PropType<MapPoint[]>,
      required: true,
    },
    options: Object as PropType<PolyInfoDisplayOptions>,
    doRouting: Boolean,
    map: {
      type: Object as PropType<LeafletMap>,
      required: true,
    },
  },
  render() {
    return [];
  },
  setup(props) {
    const routerService = DI.get<DirectionRouterService>(
      DirectionRouterService,
    );
    const { points, options, doRouting } = toRefs(props);
    const routedPoints = ref<MapPoint[]>([]);
    const polyPoints: ComputedRef<MapPoint[]> = computed(() =>
      routedPoints.value.length > 0 ? routedPoints.value : points.value,
    );
    let polyline: Polyline;

    function buildPolyline(): Polyline {
      const latlngs = polyPoints.value.map((p) => L.latLng(p.lat, p.lng));

      if (polyline) {
        polyline.setLatLngs(latlngs);
        if (options && options.value) polyline.setStyle(options.value);
      } else {
        polyline = new Polyline(latlngs, options?.value);
        polyline.addTo(props.map);
      }
      return polyline;
    }

    if (doRouting.value) {
      polyline = new Polyline([], options?.value);
      polyline.addTo(props.map);
    } else {
      polyline = buildPolyline();
    }
    watch([polyPoints, options], buildPolyline, { deep: true });

    onUnmounted(() => {
      if (polyline) {
        polyline.off();
        polyline.remove();
      }
    });

    async function requestRouting() {
      if (doRouting.value) {
        const newRoutedPoints = await routerService.getRoutedPoints(
          points.value,
        );
        routedPoints.value = newRoutedPoints.flatMap((leg) => leg[0].points);
      }
    }

    requestRouting();

    watch(
      points,
      () => {
        requestRouting();
      },
      { deep: true },
    );

    return { polyline, buildPolyline, routerService };
  },
});
