import axios from 'axios';
import { createApp } from 'vue';
import App from './App.vue';
import './styling/index.styl';
import '@fortawesome/fontawesome-free/css/all.css';
import 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'ol/ol.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import 'bootstrap/dist/css/bootstrap.css';
import router from './router';
import { bindServices } from './di';

const is_ip = window.location.hostname.match(
  /[0-9]{1,3}[.][0-9]{1,3}[.][0-9]{1,3}[.][0-9]{1,3}/,
);

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

if (
  !axios.defaults.baseURL ||
  is_ip ||
  window.location.hostname === 'localhost'
) {
  const port = process.env.VUE_APP_API_PORT;
  axios.defaults.baseURL = `${window.location.protocol}//${
    window.location.hostname
  }:${port || 3000}`;
}

axios.defaults.withCredentials = true;

bindServices();

createApp(App).use(router).mount('#app');
