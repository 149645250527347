
import { defineComponent, ref } from 'vue';
import { SearchResult } from '@/services/map-service/location-search/search-result';
import LocationFinder from './LocationFinder.vue';

export default defineComponent({
  name: 'HiddenFinder',
  components: {
    LocationFinder,
  },
  props: {
    clearOnChosen: Boolean,
    focusOnChosen: Boolean,
  },
  setup(props, { emit }) {
    const hidden = ref(true);

    function locationChosen(result: SearchResult) {
      emit('locationChosen', result);
      if (props.clearOnChosen) hidden.value = true;
    }

    return {
      hidden,
      locationChosen,
    };
  },
});
