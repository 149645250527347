import { DI } from '@/di';
import { ConsoleLogger } from '@/services/logging/console-logger.service';
import { LoggerService } from '@/services/logging/logger.interface';

function delegateLog(k: keyof LoggerService): (msg: unknown) => void {
  return (msg: unknown) => {
    let service = DI.isBound(LoggerService)
      ? DI.get<LoggerService>(LoggerService)
      : undefined;
    if (!service && process.env.JEST_WORKER_ID) service = new ConsoleLogger();
    if (service) void service[k](msg);
  };
}

export const Logger = {
  error: delegateLog('error'),
  warn: delegateLog('warn'),
  log: delegateLog('log'),
};
