import { computed, WritableComputedRef } from 'vue';

export class SettableProps<T, E = 'update:modelValue'> {
  constructor(
    private readonly props: () => T,
    private readonly emit: (key: E, v: T) => void,
    private readonly emitString: E,
  ) {}

  getPropRef<K extends keyof T>(
    prop: K,
    parser: (conv: string) => T[K],
  ): WritableComputedRef<T[K] | string> {
    return computed({
      get: () => {
        return this.props()[prop];
      },
      set: (v: T[K] | string) => {
        const result = typeof v === 'string' ? parser(v) : v;
        const out = { ...this.props() };
        out[prop] = result;
        this.emit(this.emitString, out);
      },
    });
  }
}
