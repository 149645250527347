
import { RouteLeg } from '@/services/map-service/routing/direction-router.interface';
import { RouteType } from '@/services/map-service/routing/route-type';
import { computed, defineComponent, PropType, toRef } from 'vue';
import { hoursAndMinutesComputer } from '@/parts/hour-minute-string';

const ICON_MAP = {
  [RouteType.WALK]: 'fa-walking',
  [RouteType.CAR]: 'fa-car',
};

export default defineComponent({
  name: 'LegInfoDisplay',
  props: {
    seconds: {
      type: Number,
      required: true,
    },
    type: {
      type: String as PropType<RouteType>,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
    },
  },
  setup(props: Omit<RouteLeg, 'points'> & { selected?: boolean }) {
    const iconClass = computed(() => ICON_MAP[props.type]);
    const timeString = hoursAndMinutesComputer(toRef(props, 'seconds'));
    return { timeString, iconClass };
  },
});
