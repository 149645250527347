import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "trip-creator" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.creating)
      ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          class: "trip-create-plus bi bi-plus text-center primary-clickable",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.startCreate && _ctx.startCreate(...args)))
        }))
      : (_openBlock(), _createElementBlock("form", {
          key: 1,
          class: "d-flex flex-row",
          ref: "form",
          onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"])),
          onFocusout: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.focusLeaving && _ctx.focusLeaving(...args)))
        }, [
          _withDirectives(_createElementVNode("input", {
            class: "form-control",
            id: "tc-name-input",
            ref: "nameInput",
            type: "text",
            placeholder: "Trip Name",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tripName) = $event))
          }, null, 512), [
            [_vModelText, _ctx.tripName]
          ]),
          _createElementVNode("input", {
            class: "btn btn-primary",
            id: "tc-submit",
            type: "submit",
            value: "Create",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.doCreate && _ctx.doCreate(...args)))
          })
        ], 544))
  ]))
}