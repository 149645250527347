
import { MapPoint } from '@/map-display';
import {
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  toRefs,
  watch,
} from 'vue';
import VectorSource from 'ol/source/Vector';
import Geometry from 'ol/geom/Geometry';
import Feature from 'ol/Feature';
import { fromLonLat } from 'ol/proj';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import LineString from 'ol/geom/LineString';

export default defineComponent({
  props: {
    points: {
      type: Array as PropType<MapPoint[]>,
      required: true,
    },
    color: String,
    dashPattern: Array as PropType<number[]>,
    vectorSource: {
      type: Object as PropType<VectorSource<Geometry>>,
      required: true,
    },
  },
  render() {
    return [];
  },
  setup(props) {
    const { points, color, dashPattern } = toRefs(props);
    const feature = new Feature();
    function updateGeom() {
      const mapPoints = points.value.map((mp) => fromLonLat([mp.lng, mp.lat]));
      feature.setGeometry(new LineString(mapPoints));
    }
    function updateStyle() {
      const style = new Style({
        stroke: new Stroke({
          width: 3,
          color: color?.value || '#000',
          lineDash: dashPattern?.value,
        }),
        zIndex: 2,
      });
      feature.setStyle(style);
    }

    updateStyle();
    watch([color, dashPattern], () => updateStyle(), { deep: true });
    updateGeom();
    watch(points, () => updateGeom(), { deep: true });

    onMounted(() => {
      props.vectorSource.addFeature(feature);
    });

    onUnmounted(() => {
      props.vectorSource.removeFeature(feature);
    });

    return { feature };
  },
});
