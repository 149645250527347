import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "point-time" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_duration = _resolveComponent("inline-duration")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_inline_duration, {
      mode: "single",
      suffix: {singular: 'minute', plural: 'minutes'},
      step: 5,
      modelValue: _ctx.minutes,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.minutes) = $event)),
      "button-text": _ctx.buttonText
    }, null, 8, ["modelValue", "button-text"])
  ]))
}