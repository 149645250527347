import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
import TripDetailView from '../views/TripDetailView.vue';
import TripSelector from '@/components/TripSelector.vue';
import { DI } from '@/di';
import { UserInfoService } from '@/services/user-management/user-info.interface';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Home,
    children: [
      { name: 'TripSelect', path: '', component: TripSelector },
      {
        name: 'TripDetail',
        path: '/trip/:id',
        props: true,
        component: TripDetailView,
        meta: {
          auth: true,
        },
      },
    ],
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  const userService = DI.get<UserInfoService>(UserInfoService);
  await userService.waitForLoad();
  if (!userService.getCurrentUser().value && to.meta['auth']) return '/';
  else return true;
});

export default router;
