
import { defineComponent, ref } from 'vue';
import { DI } from '../di';
import { Trip } from '@/services/trips/trip';
import { TripApiService } from '@/services/trips/trip-service.interface';
import TripCreator from './TripCreator.vue';
import { Logger } from '@/utils/logger';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: { TripCreator },
  setup() {
    const tripService = DI.get<TripApiService>(TripApiService);
    const trips = ref<Trip[]>([]);
    const router = useRouter();

    const loadTrips = async () => {
      let tripResults = await tripService.getCurrentUserTrips();
      if (tripResults != null) {
        trips.value = tripResults;
      } else {
        //TODO display an error
        Logger.error("Couldn't get trips for current user");
      }
    };
    const triggerNav = (trip: Trip) => {
      router.push({ name: 'TripDetail', params: { id: trip._id } });
    };

    const processNewTrip = (trip: Trip) => {
      trips.value.push(trip);
    };

    loadTrips();
    return {
      trips,
      loadTrips,
      triggerNav,
      processNewTrip,
    };
  },
});
