import { injectable } from 'inversify';
import {
  computed,
  ComputedRef,
  DeepReadonly,
  readonly,
  ref,
  Ref,
  toRef,
} from 'vue';
import {
  ComputedPolyline,
  ComputedPolylineManager,
  PolylineComputationId,
} from './computed-polylines.interface';

interface PolyRef {
  computation: ComputedRef<ComputedPolyline[]>;
  id: PolylineComputationId;
}

@injectable()
export class ArrayPolylineManager implements ComputedPolylineManager {
  private readonly polylines: Ref<PolyRef[]>;
  private readonly flatPolylines: ComputedRef<ComputedPolyline[]>;
  private id: number;
  constructor() {
    this.id = 0;
    this.polylines = ref([]);
    this.flatPolylines = computed(() =>
      this.polylines.value.flatMap(
        (polyref) => toRef(polyref, 'computation').value,
      ),
    );
  }
  addPolylineComputation(
    ref: ComputedRef<ComputedPolyline[]>,
  ): PolylineComputationId {
    ++this.id;
    this.polylines.value.push({
      computation: ref,
      id: this.id,
    });
    return this.id;
  }
  removePolylineComputation(id: PolylineComputationId): void {
    this.polylines.value = this.polylines.value.filter((v) => v.id != id);
  }
  getPolylines(): DeepReadonly<Ref<ComputedPolyline[]>> {
    return readonly(this.flatPolylines);
  }
}
