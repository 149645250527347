import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "city-night-count" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_duration = _resolveComponent("inline-duration")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_inline_duration, {
      mode: "single",
      suffix: {singular: 'night', plural: 'nights'},
      modelValue: _ctx.nights,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.nights) = $event))
    }, null, 8, ["modelValue"])
  ]))
}