
import { ModalControl } from '@/parts/ModalControl';
import { computed, ComputedRef, defineComponent } from 'vue';
import EdModal from './EdModal.vue';

export default defineComponent({
  name: 'FaIconModalTrigger',
  components: {
    EdModal,
  },
  props: {
    iconClass: {
      type: String,
      required: true,
    },
    closedClass: String,
  },
  setup(props) {
    const control = new ModalControl();
    const classes: ComputedRef<Record<string, boolean>> = computed(() => {
      const mapping: Record<string, boolean> = {
        [props.iconClass]: !control.showModal.value || !props.closedClass,
      };
      if (props.closedClass)
        mapping[props.closedClass] = control.showModal.value;
      return mapping;
    });
    return {
      control,
      show: control.showModal,
      classes,
    };
  },
});
