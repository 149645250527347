
import { computed, defineComponent, Ref } from 'vue';
import LeafletMap from '@/components/leaflet/LeafletMap.vue';
import OpenLayersMap from '@/components/openlayers/OpenLayersMap.vue';
import Hud from '@/components/Hud.vue';
import UserManagement from '@/components/UserManagement.vue';
import { DI } from '@/di';
import { UserInfoService } from '@/services/user-management/user-info.interface';

export default defineComponent({
  name: 'Home',
  components: {
    LeafletMap,
    OpenLayersMap,
    Hud,
    UserManagement,
  },

  setup() {
    const userService = DI.get<UserInfoService>(UserInfoService);
    const haveUser: Ref<boolean> = computed(
      () => !!userService.getCurrentUser().value,
    );
    return { haveUser };
  },
});
