import { DateTime } from 'luxon';
import { RouteType } from '../map-service/routing/route-type';

export interface Trip {
  _id: string;
  name: string;
}

export enum TripPointType {
  ARRIVAL = 'arrival-plane',
  AIRPORT = 'airport',
  BUS_STOP = 'bus',
  BOAT = 'boat',
  CABLE_CAR = 'cable-car',
  TRAIN_STATION = 'train',
  HOTEL = 'hotel',
  BUILDING = 'building',
  AREA = 'area',
  STORE = 'store',
  PARK = 'park',
  HERITAGE = 'heritage',
  SPA = 'spa',
  WATER = 'water',
  RESTAURANT = 'restaurant',
  CHURCH = 'church',
  EXPERIENCE = 'experience',
  OTHER = 'other',
}

export interface TripPoint {
  lat: number;
  lng: number;
  name: string;
  place_id: string;
}

export interface TripCityPoint extends TripPoint {
  type: TripPointType;
  id: string;
  minutes: number;
  outgoing_travel: RouteType | null;
  is_overnight: boolean;
  notes: string;
  cost?: number;

  start_hour?: number;
  start_minute?: number;
}

export interface TripCity extends TripPoint {
  enter_via: TripCityPoint | null;
  exit_via: TripCityPoint | null;
  stay_at: TripCityPoint | null;
  nights: number;

  points: TripCityPoint[];
  id: string;

  notes: string;
  cost?: number;
}

export interface TripOptions {
  dayStartHour: number;
  dayStartMinute: number;
  startDate?: DateTime;
}

export interface TripDetail extends Trip {
  cities: TripCity[];
  options: TripOptions;
  notes: string;
  cost?: number;
}
