import { computed, ComputedRef, onUnmounted, Ref } from 'vue';
import { DI } from '@/di';
import {
  DisplayablePointOfInterest,
  PointSet,
  PointSetDisplayType,
  PointSetPolyInfo,
} from '@/map-display';
import { PointSetService } from '@/services/map-service/points/point-set.interface';

/**
 * Pushes a set of points out for display on the map.
 *
 * The icon class and other display properties can be configured via the
 * `iconClass` and `polyinfo` fields.
 */
export class ManagedPointDisplay {
  private pointService = DI.get<PointSetService>(PointSetService);
  private readonly setRef: ComputedRef<PointSet>;
  iconClass?: string;
  polyinfo?: PointSetPolyInfo;
  /// Map point z index. Cannot be changed once the point set is loaded.
  zIndex = 0;
  constructor(
    private readonly points: Ref<DisplayablePointOfInterest[]>,
    private readonly displayType: PointSetDisplayType,
  ) {
    const pointsWithHover = computed(() => {
      return this.points.value.map((poi) => {
        return {
          ...poi,
          displayOptions: {
            ...poi.displayOptions,
            hoverText: poi.displayOptions?.hoverText || poi.name,
          },
        };
      });
    });
    this.setRef = computed(() => {
      const set = new PointSet(
        pointsWithHover.value,
        this.displayType,
        this.iconClass,
      );
      set.zIndex = this.zIndex;
      set.polyinfo = this.polyinfo;
      return set;
    });
  }

  destroy(): void {
    this.pointService.removePointSet(this.setRef);
  }

  register(): void {
    this.pointService.addPointSet(this.setRef);
  }

  setup(): void {
    this.register();
    onUnmounted(() => {
      this.destroy();
    });
  }
}
