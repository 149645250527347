import { PointSet } from '@/map-display';
import { DeepReadonly, Ref } from 'vue';

export const PointSetService = Symbol('PointSetService');
export interface PointSetService {
  getPointSets(): DeepReadonly<Ref<PointSet[]>>;
  removePointSet(id: Ref<PointSet>): boolean;
  addPointSet(set: Ref<PointSet>): void;
  clearPointSets(): void;
}
