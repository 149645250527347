
import { defineComponent, ref, toRef, watch } from 'vue';

export default defineComponent({
  name: 'BounceAnimation',
  props: {
    animate: Boolean,
  },
  setup(props) {
    const animationRunning = ref(false);

    function animationIterationComplete() {
      if (!props.animate) animationRunning.value = false;
    }

    watch(toRef(props, 'animate'), (newAnim) => {
      if (newAnim) {
        animationRunning.value = true;
      }
    });

    return { animationRunning, animationIterationComplete };
  },
});
