
import { TripCityPoint } from '@/services/trips/trip';
import { computed, defineComponent, PropType } from 'vue';
import InlineDuration from '@/components/utils/InlineDuration.vue';
import { DateTime } from 'luxon';

import { displayTimeRange } from '@/utils/time-fmt';

export default defineComponent({
  components: {
    InlineDuration,
  },
  props: {
    modelValue: {
      type: Object as PropType<TripCityPoint>,
      required: true,
    },
    startTime: {
      type: Object as PropType<DateTime>,
    },
    endTime: {
      type: Object as PropType<DateTime>,
    },
    useTimeOfDay: Boolean,
  },
  setup(props, { emit }) {
    const minutes = computed({
      get() {
        return props.modelValue.minutes;
      },
      set(minutes: number) {
        let clone = { ...props.modelValue };
        clone.minutes = minutes;
        emit('update:modelValue', clone);
      },
    });

    const buttonText = computed(() =>
      props.useTimeOfDay && props.startTime && props.endTime
        ? displayTimeRange(props.startTime, props.endTime)
        : undefined,
    );
    return {
      minutes,
      buttonText,
    };
  },
});
