export interface Identifiable {
  id: string;
}
export interface NormalizedList<T extends Identifiable> {
  ordering: string[];
  values: Record<string, T>;
}

export function normalizeList<T extends Identifiable>(
  list: T[],
): NormalizedList<T> {
  const result: NormalizedList<T> = {
    ordering: list.map((v) => v.id),
    values: {},
  };
  list.forEach((elem) => {
    result.values[elem.id] = elem;
  });
  return result;
}

export function normalizedCopyAndAppend<T extends Identifiable>(
  list: NormalizedList<T>,
  item: T,
): NormalizedList<T> {
  const clone = { ...list };
  clone.values[item.id] = item;
  clone.ordering.push(item.id);
  return clone;
}

export function rebuildFromNormalized<T extends Identifiable>(
  normalized: NormalizedList<T>,
): T[] {
  return normalized.ordering.map((id) => normalized.values[id]);
}
