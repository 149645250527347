
import { defineComponent, nextTick, ref } from 'vue';
import { DI } from '../di';
import { TripApiService } from '../services/trips/trip-service.interface';

export default defineComponent({
  setup(_props, { emit }) {
    const tripService = DI.get<TripApiService>(TripApiService);
    const creating = ref(false);
    const form = ref<HTMLFormElement | null>(null);
    const nameInput = ref<HTMLInputElement | null>(null);
    const tripName = ref('');

    const startCreate = () => {
      creating.value = true;
      nextTick(() => nameInput.value?.focus());
    };

    const doCreate = async () => {
      let trip = await tripService.newTrip(tripName.value);
      if (trip) {
        emit('newTrip', trip);
        tripName.value = '';
        creating.value = false;
      } else {
        // TODO better error handling, show message
      }
    };

    const focusLeaving = (evt: FocusEvent) => {
      if (
        evt.relatedTarget &&
        !form.value?.contains(evt.relatedTarget as Node)
      ) {
        creating.value = false;
        tripName.value = '';
      }
    };

    return {
      creating,
      tripName,
      nameInput,
      startCreate,
      doCreate,
      form,
      focusLeaving,
    };
  },
});
