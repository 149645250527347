
import { defineComponent, onMounted, onUnmounted, ref, Ref } from 'vue';
import {
  GlobalClickObserver,
  GlobalClickTracker,
} from '@/services/focus/global-click-tracker.interface';
import { DI } from '@/di';

export default defineComponent({
  name: 'EdModal',
  emits: ['hide'],
  setup(_props, ctx) {
    const tracker = DI.get<GlobalClickTracker>(GlobalClickTracker);
    const modal: Ref<HTMLElement | null> = ref(null);

    const obs: GlobalClickObserver = {
      onClick(target: EventTarget) {
        if (
          modal.value &&
          target instanceof Node &&
          !modal.value.contains(target)
        ) {
          ctx.emit('hide');
        }
      },
    };

    onMounted(() => tracker.addObserver(obs));
    onUnmounted(() => tracker.removeObserver(obs));

    return {
      modal,
    };
  },
});
