
import { computed, defineComponent, PropType, ref } from 'vue';
import EdPopup from '@/components/utils/EdPopup.vue';
import { DurationMode } from '@/utils/duration';

export default defineComponent({
  name: 'InlineDuration',
  components: {
    EdPopup,
  },
  props: {
    mode: {
      type: String as PropType<DurationMode>,
      required: true,
    },
    modelValue: Number,
    suffix: [String, Object] as PropType<
      string | { singular: string; plural: string }
    >,
    step: Number,
    buttonText: String,
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const picking = ref(false);
    const singleStep = computed(() => (props.step ? props.step : 1));
    const storedValue = ref(props.modelValue);

    const currentSuffix = computed(() => {
      if (!props.suffix) return '';
      else if (typeof props.suffix === 'string') return props.suffix;
      else
        return storedValue.value === 1
          ? props.suffix.singular
          : props.suffix.plural;
    });
    const btnText = computed(() => {
      if (props.buttonText) return props.buttonText;
      const mainText = `${storedValue.value}`;
      return `${mainText} ${currentSuffix.value}`;
    });
    function popupHidden() {
      picking.value = false;
      const invalid =
        typeof storedValue.value !== 'number' || isNaN(storedValue.value);
      emit('update:modelValue', invalid ? 0 : storedValue.value);
      if (invalid) {
        storedValue.value = 0;
      }
    }
    function buttonClick() {
      if (!picking.value) picking.value = true;
    }
    return {
      btnText,
      currentSuffix,
      picking,
      singleStep,
      storedValue,
      popupHidden,
      buttonClick,
    };
  },
});
