import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "leg-time" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["leg-info-root d-flex flex-row align-items-center justify-content-center small ps-1 pe-1", {'leg-info-selected': _ctx.selected}])
  }, [
    _createElementVNode("i", {
      class: _normalizeClass(["fas pe-1 leg-info-icon", _ctx.iconClass])
    }, null, 2),
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.timeString), 1)
  ], 2))
}