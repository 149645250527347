import { injectable } from 'inversify';
import { computed, ComputedRef, DeepReadonly, reactive, Ref } from 'vue';
import { PointSet } from '../../../map-display';
import { PointSetService } from './point-set.interface';

@injectable()
export class ArrayPointSetService implements PointSetService {
  private pointSets: Ref<PointSet>[];
  private readonly resolvedSets: ComputedRef<PointSet[]>;
  constructor() {
    this.pointSets = reactive([]);
    this.resolvedSets = computed(() => {
      return this.pointSets.map((r) => r.value);
    });
  }

  getPointSets(): DeepReadonly<Ref<PointSet[]>> {
    return this.resolvedSets;
  }

  removePointSet(set: Ref<PointSet>): boolean {
    const idx = this.pointSets.findIndex((r) => r == set);

    if (idx >= 0) this.pointSets.splice(idx, 1);

    return idx >= 0;
  }

  addPointSet(ps: Ref<PointSet>): void {
    this.pointSets.push(ps);
  }

  clearPointSets(): void {
    this.pointSets.length = 0;
  }
}
