
import { DI } from '@/di';
import { TripCityPoint, TripPointType } from '@/services/trips/trip';
import { TripIconSet } from '@/services/trips/trip-icon-set.interface';
import { defineComponent, PropType, ref } from 'vue';

export default defineComponent({
  name: 'CityPointConfig',
  props: {
    modelValue: {
      type: Object as PropType<TripCityPoint>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const icons = DI.get<TripIconSet>(TripIconSet);
    const newName = ref('');

    const iconOptions = Object.values(TripPointType).map((k) => ({
      value: k,
      iconClass: icons.getTripIconClass(k),
    }));

    function setIcon(value: TripPointType) {
      const clone = { ...props.modelValue };
      clone.type = value;
      emit('update:modelValue', clone);
    }

    function setName() {
      if (newName.value.length === 0) return;
      const clone = { ...props.modelValue };
      clone.name = newName.value;
      emit('update:modelValue', clone);
    }

    return {
      iconOptions,
      setIcon,
      newName,
      setName,
    };
  },
});
