import { SearchResult } from '@/services/map-service/location-search/search-result';
import { Trip, TripCity, TripCityPoint, TripDetail } from './trip';

export const TripApiService = Symbol('TripApiService');

export enum TripDetailError {
  // This may also mean the trip was not authorized, but the server doesn't
  // actually tell us which one it is
  NOT_FOUND,
  UNKNOWN,
}
export interface TripApiService {
  getCurrentUserTrips(): Promise<Trip[] | null>;
  getTripDetail(tripId: string): Promise<TripDetail | TripDetailError>;
  updateTrip(trip: TripDetail): Promise<boolean>;
  newTrip(tripName: string): Promise<Trip | null>;
  createCityFromSearch(result: SearchResult): TripCity;
  createPointFromSearch(result: SearchResult): TripCityPoint;
  //  deleteTrip(trip: Trip): Promise<boolean>
}
