import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = {
  key: 0,
  class: "text-danger fas fa-exclamation-circle small me-1",
  title: "Pick a result to save search changes."
}
const _hoisted_3 = { class: "ed-scrollable lf-result-root" }
const _hoisted_4 = ["onClick", "onMouseover", "onMouseleave"]
const _hoisted_5 = {
  key: 0,
  class: "result-detail"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ed_popup = _resolveComponent("ed-popup")!

  return (_openBlock(), _createElementBlock("div", {
    class: "lf-root align-middle w-100",
    title: _ctx.hoverInfoText
  }, [
    _createVNode(_component_ed_popup, {
      showPopup: _ctx.showPopup,
      options: {placement: 'bottom', arrow: false, interactive: true},
      onHidden: _cache[5] || (_cache[5] = ($event: any) => (_ctx.wasHidden = true))
    }, {
      root: _withCtx(() => [
        _createElementVNode("div", {
          class: "lf-name-container d-flex align-items-center lf-core w-100",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.swapToSearch()))
        }, [
          (!_ctx.editing && _ctx.nameMismatch)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(["lf-location-name lf-core", {invisible: _ctx.editing, "text-secondary": !_ctx.locationName }])
          }, _toDisplayString(_ctx.locationName || _ctx.fallbackInfoText), 3),
          _withDirectives(_createElementVNode("input", {
            class: _normalizeClass(["lf-location-search lf-core form-control w-100", {invisible: !_ctx.editing}]),
            ref: "searchBox",
            type: "search",
            placeholder: "Find a Location",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.locationName) = $event)),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selected=true)),
            onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.maybeUnsearch($event))),
            onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.loadFinalResults()), ["enter"]))
          }, null, 34), [
            [_vModelText, _ctx.locationName]
          ])
        ])
      ]),
      popup: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass(["lf-results", {show: _ctx.showResults}]),
            ref: "resultDiv"
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (res, i) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "d-flex flex-column lf-single-result p-1",
                onClick: ($event: any) => (_ctx.itemClicked(i, res)),
                onMouseover: ($event: any) => (_ctx.setHighlight(i)),
                onMouseleave: ($event: any) => (_ctx.removeHighlight(i))
              }, [
                _createElementVNode("div", null, _toDisplayString(res.name), 1),
                (res.detail)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(res.detail), 1))
                  : _createCommentVNode("", true)
              ], 40, _hoisted_4))
            }), 256))
          ], 2)
        ])
      ]),
      _: 1
    }, 8, ["showPopup"])
  ], 8, _hoisted_1))
}