
import { computed, defineComponent, onUnmounted, PropType } from 'vue';
import { TripCity } from '@/services/trips/trip';

import EditableTitle from '@/components/utils/inputs/EditableTitle.vue';
import CityNightCount from '@/components/trip-view/CityNightCount.vue';
import CostDisplay from '@/components/utils/CostDisplay.vue';
import { Debouncer } from '@/utils/Debouncer';
import { TotalCityCostCalculator } from '@/parts/TotalCityCostCalculator';

const NOTE_DEBOUNCE = 1000;

export default defineComponent({
  components: {
    EditableTitle,
    CityNightCount,
    CostDisplay,
  },
  props: {
    modelValue: {
      required: true,
      type: Object as PropType<TripCity>,
    },
  },
  setup(props, { emit }) {
    const changeDebounce = new Debouncer(
      NOTE_DEBOUNCE,
      (data: Partial<TripCity>) => patchAndEmitCity(data),
      (existing: Partial<TripCity>, newData: Partial<TripCity>) => ({
        ...existing,
        ...newData,
      }),
    );

    function patchAndEmitCity(patch: Partial<TripCity>) {
      const newCity: TripCity = { ...props.modelValue, ...patch };
      emit('update:modelValue', newCity);
    }

    function scheduleCostUpdate(cost: number) {
      changeDebounce.startDebounce({ cost });
    }

    function scheduleNoteUpdate(event: InputEvent) {
      const notes = (event?.target as HTMLTextAreaElement).value;
      changeDebounce.startDebounce({ notes });
    }

    onUnmounted(() => {
      changeDebounce.fireImmediately();
    });

    const cityCost = computed(() =>
      new TotalCityCostCalculator([props.modelValue]).totalCost(),
    );

    return {
      patchAndEmitCity,
      scheduleNoteUpdate,
      scheduleCostUpdate,
      cityCost,
    };
  },
});
