
import { computed, defineComponent, nextTick, ref } from 'vue';
import { DI } from '../di';
import {
  UserInfo,
  UserInfoService,
} from '@/services/user-management/user-info.interface';

export default defineComponent({
  name: 'UserManagement',
  setup() {
    const infoService = DI.get<UserInfoService>(UserInfoService);
    const username = ref('');
    const password = ref('');
    const doingSignup = ref(false);
    const expanded = ref(false);
    const usernameInput = ref<HTMLInputElement | null>(null);
    const rootDiv = ref<HTMLDivElement | null>(null);
    const currentUser = infoService.getCurrentUser();
    const logoutButton = ref<HTMLInputElement | null>(null);

    const errorMessage = infoService.userManagementErrorMessage
      ? infoService.userManagementErrorMessage
      : ref(null);

    async function handleSubmit() {
      let result: boolean | UserInfo | null = null;
      if (doingSignup.value) {
        result = await infoService.doSignup(username.value, password.value);
      } else {
        result = await infoService.doLogin(username.value, password.value);
        if (result != null) expanded.value = false;
      }
      if (result) {
        doingSignup.value = false;
        username.value = '';
        password.value = '';
      }
    }

    function openForm() {
      if (!expanded.value) {
        expanded.value = true;
        if (currentUser.value) nextTick(() => logoutButton.value?.focus());
        else nextTick(() => usernameInput.value?.focus());
      }
    }

    function focusLeaving(evt: FocusEvent) {
      //Don't hide on window/tab change
      if (evt.relatedTarget == null) return;
      if (!rootDiv.value?.contains(evt.relatedTarget as Node))
        expanded.value = false;
    }

    async function doLogout() {
      await infoService.doLogout();
      expanded.value = false;
    }

    const submitText = computed(() =>
      doingSignup.value ? 'Sign Up' : 'Log In',
    );

    const swapText = computed(() =>
      doingSignup.value ? 'Switch to Login' : 'Switch to Signup',
    );

    const formClasses = computed(() => {
      return { collapsed: !expanded.value };
    });

    return {
      username,
      password,
      errorMessage,
      doingSignup,
      handleSubmit,
      expanded,
      currentUser,
      openForm,
      submitText,
      swapText,
      usernameInput,
      rootDiv,
      logoutButton,
      focusLeaving,
      formClasses,
      doLogout,
    };
  },
});
