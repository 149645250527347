import { MapPoint } from '@/map-display';
import axios from 'axios';
import { injectable } from 'inversify';
import {
  DirectionRouterService,
  RouteOptions,
} from './direction-router.interface';
import { RoutingResultCache } from './routing-result-cache';
import { RouteType } from './route-type';
import { Logger } from '@/utils/logger';
import * as polyline from '@mapbox/polyline';

const MAX_RESULT_CACHE_SIZE = 50;
const POLYLINE_PRECISION = 6;

export enum PathTravelType {
  WALK = 'walk',
  CAR = 'car',
}

interface PathResult {
  routes: {
    type: PathTravelType;
    legs: {
      points: string;
      seconds: number;
    }[];
  }[];
}

const PATH_TO_ROUTE_TYPE = {
  [PathTravelType.CAR]: RouteType.CAR,
  [PathTravelType.WALK]: RouteType.WALK,
};

@injectable()
export class BackendRouterService implements DirectionRouterService {
  private readonly lru_cache = new RoutingResultCache(MAX_RESULT_CACHE_SIZE);

  async getRawRoutes(points: MapPoint[]): Promise<PathResult> {
    const req = polyline.encode(
      points.map((p) => [p.lat, p.lng]),
      POLYLINE_PRECISION,
    );
    const result = await axios.post<PathResult>('/pathfinding/v1/polystring', {
      polystring: req,
    });
    return result.data;
  }

  async getRoutedPoints(points: MapPoint[]): Promise<RouteOptions> {
    if (points.length <= 1) {
      return [
        points.map((p) => ({
          points: [p],
          seconds: 0,
          type: RouteType.WALK,
        })),
      ];
    }

    const strippedPoints = points.map((p) => ({ lat: p.lat, lon: p.lng }));

    const ident = JSON.stringify(strippedPoints);

    const cached = this.lru_cache.getResult(ident);
    if (cached) return cached;
    try {
      const res = await this.getRawRoutes(points);
      const options = res.routes.map((route) =>
        route.legs.map((leg) => {
          const polyTuples = polyline.decode(leg.points, POLYLINE_PRECISION);
          const points = polyTuples.map((tuple) => ({
            lat: tuple[0],
            lng: tuple[1],
          }));
          return {
            points,
            seconds: leg.seconds,
            type: PATH_TO_ROUTE_TYPE[route.type],
          };
        }),
      );

      this.lru_cache.insertResult(ident, options);
      return options;
    } catch (e) {
      Logger.warn('Could not get route from backend pathfinding API');
      Logger.warn(e);
      return [
        points.map((p) => ({
          points: [p],
          seconds: -1,
          type: RouteType.WALK,
        })),
      ];
    }
  }
}
