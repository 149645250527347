import { PolylineOptions } from 'leaflet';

export interface MapPoint {
  lat: number;
  lng: number;
}

export interface PointOfInterest extends MapPoint {
  name: string;
}

export interface RawPointSet {
  points: MapPoint[];
}

export type PointSetId = symbol;

export interface PointDisplayOptions {
  iconClass?: string;
  color?: string;
  highlightColor?: string;
  zIndex?: number;
  hoverText?: string;
  isHighlighted?: boolean;
}

export interface DisplayableMapPoint extends MapPoint {
  displayOptions?: PointDisplayOptions;
}

export interface PointSetPolyInfo {
  color?: string;
  dashes?: number[];
}

export type PolyInfoDisplayOptions = Omit<PolylineOptions, 'renderer'>;

export interface PointWithId {
  id: string;
  locked?: boolean;
}

export type DisplayablePointOfInterest = PointOfInterest &
  DisplayableMapPoint &
  PointWithId;

export enum PointSetDisplayType {
  NoDisplay = 0,
  Markers = 1 << 0,
  Polyline = 1 << 1,
  All = ~(~0 << 2),
}
export class PointSet<P extends DisplayableMapPoint = DisplayableMapPoint> {
  readonly id: PointSetId = Symbol();
  iconClass: string;
  color: string;
  zIndex = 0;
  polyinfo?: PointSetPolyInfo;
  constructor(
    public points: P[],
    public displayType: PointSetDisplayType,
    iconClass?: string,
    color?: string,
  ) {
    this.iconClass = iconClass ? iconClass : '';
    this.color = color ? color : '';
  }
}

export type MapMarkerProps = PointDisplayOptions & { point: MapPoint };

/**
 * Some common categories for points of interest.
 */
export enum InterestType {
  ACCOMODATION = 'accomodation',
  BUILDING = 'building',
  CATERING = 'restaurant',
  NATURE = 'nature',
  TOURISM = 'tourism',
  LEISURE = 'leisure',
}
