import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-100 d-flex flex-row justify-content-center" }
const _hoisted_2 = { class: "w-50 d-flex flex-row justify-content-evenly pb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_leg_info_display = _resolveComponent("leg-info-display")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pointRouteTypes, (ty) => {
        return (_openBlock(), _createBlock(_component_leg_info_display, _mergeProps(_ctx.getTypeInfoBinding(ty), {
          onClick: ($event: any) => (_ctx.setSelectedTravelType(ty))
        }), null, 16, ["onClick"]))
      }), 256))
    ])
  ]))
}