import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-row align-items-center justify-content-between" }
const _hoisted_2 = {
  key: 3,
  class: "pli-lf pli-locked flex-grow-1 h-100 small"
}
const _hoisted_3 = {
  key: 4,
  class: "pl-inline-item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_location_finder = _resolveComponent("location-finder")!
  const _component_fa_icon_modal_trigger = _resolveComponent("fa-icon-modal-trigger")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["pli-root ps-1 pe-1", _ctx.itemClass ? [_ctx.itemClass] : []])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("i", {
        class: _normalizeClass(["pli-handle bi-list primary-clickable pli-on-hover me-1", {invisible: !_ctx.handle}])
      }, null, 2),
      (!_ctx.locked && _ctx.allowExpansion)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            class: _normalizeClass(["pli-expand faded primary-clickable bi-chevron-right m-auto me-1", {expanded: _ctx.expanded}]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.expanded=!_ctx.expanded))
          }, null, 2))
        : _createCommentVNode("", true),
      (!!_ctx.iconClass)
        ? (_openBlock(), _createElementBlock("i", {
            key: 1,
            class: _normalizeClass(["pli-icon me-1", [_ctx.iconClass]])
          }, null, 2))
        : _createCommentVNode("", true),
      (!_ctx.locked)
        ? (_openBlock(), _createBlock(_component_location_finder, {
            key: 2,
            class: "pli-lf flex-grow-1 h-100",
            location: _ctx.name,
            onLocationChosen: _ctx.emitPointChange,
            "focus-on-chosen": true,
            "fallback-info-text": _ctx.itemDescription,
            "hover-info-text": _ctx.itemHover
          }, null, 8, ["location", "onLocationChosen", "fallback-info-text", "hover-info-text"]))
        : _createCommentVNode("", true),
      (_ctx.locked)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.name), 1))
        : _createCommentVNode("", true),
      (_ctx.allowInline)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "inline")
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["pli-on-hover pli-settings", {invisible: !_ctx.allowSettings}])
      }, [
        _createVNode(_component_fa_icon_modal_trigger, {
          "icon-class": "fa-up-right-and-down-left-from-center",
          "closed-class": "fa-down-left-and-up-right-to-center"
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "settings")
          ]),
          _: 3
        })
      ], 2),
      _createElementVNode("i", {
        class: _normalizeClass(["pli-center pli-on-hover bi-geo-alt-fill primary-clickable m-auto ms-1 me-1", {invisible: !_ctx.allowCentering}]),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.centerMapOnPoint && _ctx.centerMapOnPoint(...args)))
      }, null, 2),
      _createElementVNode("i", {
        class: _normalizeClass(["pli-delete pli-on-hover bi-trash-fill danger-clickable m-auto", {invisible: _ctx.locked || !_ctx.allowDelete}]),
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.emitDelete && _ctx.emitDelete(...args)))
      }, null, 2)
    ]),
    (!_ctx.locked && _ctx.doExpansion)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["w-100", {"p-1": _ctx.padAll}])
        }, [
          (!_ctx.locked && _ctx.doExpansion)
            ? _renderSlot(_ctx.$slots, "expansion", { key: 0 })
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}