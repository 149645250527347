import { RouteOptions } from './direction-router.interface';

export class RoutingResultCache {
  private readonly mapping = new Map<string, RouteOptions>();
  private lru: string[] = [];
  constructor(private readonly limit: number) {}

  refreshUsage(ident: string): void {
    this.lru = this.lru.filter((val) => val != ident);
    this.lru.push(ident);
  }

  insertResult(ident: string, points: RouteOptions): void {
    while (this.lru.length >= this.limit) {
      this.mapping.delete(this.lru[0]);
      this.lru.shift();
    }
    this.refreshUsage(ident);
    this.mapping.set(ident, points);
  }

  getResult(ident: string): RouteOptions | null {
    const cached = this.mapping.get(ident);
    if (cached) {
      this.refreshUsage(ident);
      return cached;
    } else {
      return null;
    }
  }
}
