
import {
  defineComponent,
  onMounted,
  PropType,
  shallowRef,
  toRef,
  watch,
} from 'vue';
import tippy, { Instance, Placement } from 'tippy.js';
import { PositioningStrategy } from '@popperjs/core';

export interface EdPopupOptions {
  placement: Placement | undefined;
}

export default defineComponent({
  props: {
    showPopup: Boolean,
    options: Object as PropType<EdPopupOptions>,
  },
  emits: ['hidden'],
  setup(props, { emit }) {
    const root = shallowRef<HTMLElement | null>(null);
    const popup = shallowRef<HTMLElement | null>(null);
    let tooltip = shallowRef<Instance | null>(null);
    function buildOptions() {
      return {
        trigger: 'manual',
        content: popup.value || undefined,
        interactive: true,
        theme: 'light',
        onHidden() {
          emit('hidden');
        },
        popperOptions: {
          strategy: 'fixed' as PositioningStrategy,
        },
        ...props.options,
      };
    }
    const openPopup = function () {
      if (!root.value || !popup.value) return;
      if (!tooltip.value) {
        tooltip.value = tippy(root.value, buildOptions());
      }
    };
    watch(toRef(props, 'showPopup'), () => {
      if (tooltip.value) {
        if (props.showPopup) {
          tooltip.value.show();
          // Popups have a tendency to create spurious selections of all text
          // within the popup body. Normally, clearing all selections like this
          // might have side effects, but because the popup has just been
          // triggered by clicking the root element, this should be okay
          if (window.getSelection) {
            if (window.getSelection()?.empty) {
              // Chrome
              window.getSelection()?.empty();
            } else if (window.getSelection()?.removeAllRanges) {
              // Firefox
              window.getSelection()?.removeAllRanges();
            }
          }
        } else {
          tooltip.value.hide();
        }
      }
    });
    watch(toRef(props, 'options'), () => {
      if (tooltip.value && popup.value) {
        tooltip.value.setProps(buildOptions());
      }
    });

    onMounted(openPopup);

    return {
      root,
      popup,
      tooltip,
    };
  },
});
