import { DeepReadonly, Ref } from 'vue';

export interface UserInfo {
  username: string;
}

export const UserInfoService = Symbol('UserInfoService');

export interface UserInfoService {
  getCurrentUser(): DeepReadonly<Ref<UserInfo | null>>;
  /**
   * Waits for initial user info loading to complete
   */
  waitForLoad(): Promise<void>;
  /*
   * Refresh the current user. If the request fails due to a network issue, this
   * will keep the existing user info, otherwise it will be updated.
   */
  refreshCurrentUser(): Promise<UserInfo | null>;
  doLogin(username: string, password: string): Promise<UserInfo | null>;
  doLogout(): Promise<boolean>;
  doSignup(username: string, password: string): Promise<boolean>;
  userManagementErrorMessage?: Ref<string | null>;
}
