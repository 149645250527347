import { ref, onUnmounted, Ref, computed, ComputedRef } from 'vue';
import { DI } from '@/di';
import {
  SEARCH_PIN_ICON_CLASS,
  SEARCH_HIGHLIGHT_PIN_COLOR,
  SEARCH_PIN_Z_INDEX,
} from '@/icon-styling';
import {
  DisplayableMapPoint,
  PointSet,
  PointSetDisplayType,
} from '@/map-display';
import { MapDisplayInfoService } from '@/services/map-service/display-info/map-display-info.interface';
import { SearchResult } from '@/services/map-service/location-search/search-result';
import { PointSetService } from '@/services/map-service/points/point-set.interface';

/**
 * Manages the display of search results on the map.
 */
export class ResultDisplayManager {
  readonly pointSetService = DI.get<PointSetService>(PointSetService);
  readonly mapService = DI.get<MapDisplayInfoService>(MapDisplayInfoService);
  displayed: ComputedRef<PointSet<DisplayableMapPoint>>;
  mounted = ref(true);
  private readonly points: Ref<DisplayableMapPoint[]>;
  private readonly highlighted: Ref<number> = ref(-1);

  constructor(
    /// A list of results to display
    private readonly results: Ref<SearchResult[]>,
    /// Whether the map should be focused on a result when it gets highlighted
    private readonly focusOnHighlight: Ref<boolean>,
  ) {
    this.points = computed(() =>
      this.mounted.value
        ? this.results.value.map((res, idx) => ({
            displayOptions: {
              color:
                idx == this.highlighted.value ? SEARCH_HIGHLIGHT_PIN_COLOR : '',
            },
            ...res.location,
          }))
        : [],
    );

    this.displayed = computed(() => {
      const set = new PointSet(
        this.points.value,
        PointSetDisplayType.Markers,
        SEARCH_PIN_ICON_CLASS,
      );
      set.zIndex = SEARCH_PIN_Z_INDEX;
      return set;
    });
    this.pointSetService.addPointSet(this.displayed);
  }

  setHighlight(index: number): void {
    this.highlighted.value = index;
    if (this.focusOnHighlight.value) this.focusMapOnResult(index);
  }

  removeHighlight(index: number): void {
    if (this.highlighted.value == index) this.highlighted.value = -1;
  }

  focusMapOnResult(index: number): void {
    this.mapService.setMapCenter(this.results.value[index].location);
  }

  setup(): void {
    onUnmounted(() => this.pointSetService.removePointSet(this.displayed));
  }
}
