import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "idn-pop d-flex flex-row flex-nowrap align-items-middle" }
const _hoisted_2 = ["step"]
const _hoisted_3 = { class: "idn-suffix m-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ed_popup = _resolveComponent("ed-popup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ed_popup, {
      showPopup: _ctx.picking,
      options: {placement: 'bottom'},
      onHidden: _ctx.popupHidden
    }, {
      root: _withCtx(() => [
        _createElementVNode("button", {
          class: "idn-btn text-nowrap btn btn-sm btn-secondary ms-1 me-1",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.buttonClick && _ctx.buttonClick(...args)), ["stop"]))
        }, _toDisplayString(_ctx.btnText), 1)
      ]),
      popup: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _withDirectives(_createElementVNode("input", {
            class: "form-control form-control-sm",
            type: "number",
            min: "0",
            step: _ctx.singleStep,
            onMouseover: _cache[1] || (_cache[1] = ($event: any) => ($event.target.focus())),
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.storedValue) = $event))
          }, null, 40, _hoisted_2), [
            [
              _vModelText,
              _ctx.storedValue,
              void 0,
              { number: true }
            ]
          ]),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.currentSuffix), 1)
        ])
      ]),
      _: 1
    }, 8, ["showPopup", "onHidden"])
  ]))
}