import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { ref: "root" }
const _hoisted_2 = { ref: "popup" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "root")
    ], 512),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "popup")
    ], 512)
  ]))
}