import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.editing)
      ? (_openBlock(), _createElementBlock("h2", {
          key: 0,
          class: "edit-title-header m-0",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.headerClicked && _ctx.headerClicked(...args)))
        }, _toDisplayString(_ctx.modelValue), 1))
      : _createCommentVNode("", true),
    (_ctx.editing)
      ? _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 1,
          class: "edit-title-input form-control-lg text-center",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentTitle) = $event)),
          ref: "input",
          onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.editComplete && _ctx.editComplete(...args)), ["enter"]))
        }, null, 544)), [
          [_vModelText, _ctx.currentTitle]
        ])
      : _createCommentVNode("", true)
  ], 64))
}