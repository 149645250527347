import { injectable } from 'inversify';
import {
  GlobalClickObserver,
  GlobalClickTracker,
} from './global-click-tracker.interface';

@injectable()
export class DocumentClickTracker implements GlobalClickTracker {
  private observers: GlobalClickObserver[] = [];
  private callback: (evt: MouseEvent) => void;

  constructor() {
    this.callback = this.notify.bind(this);
    document.addEventListener('mousedown', this.callback);
  }

  private notify(evt: MouseEvent) {
    this.observers.forEach((obs) => obs.onClick(evt.target));
  }

  addObserver(obs: GlobalClickObserver): void {
    this.observers.push(obs);
  }

  removeObserver(remove: GlobalClickObserver): void {
    this.observers = this.observers.filter((obs) => obs != remove);
  }

  unregister(): void {
    document.removeEventListener('mousedown', this.callback);
  }
}
