import { DI } from '@/di';
import { PointSetService } from '@/services/map-service/points/point-set.interface';
import { MapPoint, PointSetDisplayType } from '@/map-display';
import { computed, ComputedRef, DeepReadonly } from 'vue';
import { BASE_PATH_COLOR } from '@/icon-styling';
import { ComputedPolylineManager } from '@/services/map-service/points/computed-polylines.interface';

type ComputedPoly = DeepReadonly<{
  points: MapPoint[];
  color?: string;
  dashPattern?: number[];
}>;
export class PolylineFinder {
  private readonly setService = DI.get<PointSetService>(PointSetService);
  private readonly polyManager = DI.get<ComputedPolylineManager>(
    ComputedPolylineManager,
  );

  private buildOptions(
    color?: string,
    dashArray?: string,
    dashOffset?: string,
  ) {
    return {
      color: color && color.length > 0 ? color : BASE_PATH_COLOR,
      dashArray,
      dashOffset: dashOffset || (dashArray ? '0' : undefined),
    };
  }

  readonly lines: ComputedRef<ComputedPoly[]> = computed(() => {
    const pointSetPolys: ComputedPoly[] = this.setService
      .getPointSets()
      .value.filter(
        (s) =>
          (s.displayType & PointSetDisplayType.Polyline) ===
          PointSetDisplayType.Polyline,
      )
      .map((s) => {
        const { color, points } = s;
        const polyColor = s.polyinfo?.color;
        const polyDash = s.polyinfo?.dashes;
        const specColor = polyColor && polyColor.length > 0 ? polyColor : color;
        return {
          color: specColor,
          dashPattern: polyDash,
          points: points.map((p) => ({
            lat: p.lat,
            lng: p.lng,
          })),
        };
      });

    const computedPolys: ComputedPoly[] = this.polyManager
      .getPolylines()
      .value.map((compPoly) => {
        const polyColor = compPoly.displayInfo.color;
        const polyDash = compPoly.displayInfo.dashes;
        return {
          color: polyColor,
          dashPattern: polyDash,
          points: compPoly.points,
        };
      });
    return pointSetPolys.concat(computedPolys);
  });
}
