import { injectable } from 'inversify';
import { TripPointType } from './trip';
import { TripIconSet } from './trip-icon-set.interface';

function ic(name: string) {
  return `fas fa-${name}`;
}
@injectable()
export class FAIconSet implements TripIconSet {
  getTripIconClass(type: TripPointType): string {
    switch (type) {
      case TripPointType.ARRIVAL:
        return ic('plane-arrival');
      case TripPointType.AIRPORT:
        return ic('plane-departure');
      case TripPointType.BUS_STOP:
        return ic('bus');
      case TripPointType.BOAT:
        return ic('ship');
      case TripPointType.CABLE_CAR:
        return ic('tram');
      case TripPointType.TRAIN_STATION:
        return ic('train');
      case TripPointType.HOTEL:
        return ic('hotel');
      case TripPointType.BUILDING:
        return ic('building');
      case TripPointType.AREA:
        return ic('map');
      case TripPointType.STORE:
        return ic('store');
      case TripPointType.PARK:
        return ic('tree');
      case TripPointType.HERITAGE:
        return ic('landmark');
      case TripPointType.SPA:
        return ic('spa');
      case TripPointType.WATER:
        return ic('water');
      case TripPointType.RESTAURANT:
        return ic('utensils');
      case TripPointType.CHURCH:
        return ic('church');
      case TripPointType.EXPERIENCE:
        return ic('ticket-alt');

      default:
        return 'fas fa-map-signs';
    }
  }
}
