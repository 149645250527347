import { Ref, shallowRef, watch, WatchOptions, WatchSource } from 'vue';
import { Logger } from './logger';

export class AsyncRef<T> {
  private readonly syncCallback: (err?: unknown) => T;
  private readonly errCallback?: (err: unknown) => T;
  private valueRef: Ref<T>;
  doSyncOnWatchTrigger = true;
  /* eslint-disable @typescript-eslint/ban-types */
  constructor(
    watchSrc: (object | WatchSource<unknown>)[] | WatchSource<unknown>,
    syncCb: T | ((err?: unknown) => T),
    private readonly asyncCallback: () => Promise<T>,
    watchOptsOrErrFn?: WatchOptions<false> | ((err: unknown) => T),
    watchOptsWithFn?: WatchOptions<false>,
  ) {
    /* eslint-enable @typescript-eslint/ban-types */
    this.syncCallback = syncCb instanceof Function ? syncCb : () => syncCb;
    this.valueRef = shallowRef(this.syncCallback());
    void this.doAsyncUpdate();

    const watchOpts =
      typeof watchOptsOrErrFn === 'function'
        ? watchOptsWithFn
        : watchOptsOrErrFn;

    if (typeof watchOptsOrErrFn === 'function')
      this.errCallback = watchOptsOrErrFn;

    watch(
      watchSrc,
      () => {
        if (this.doSyncOnWatchTrigger)
          this.valueRef.value = this.syncCallback();
        void this.doAsyncUpdate();
      },
      watchOpts,
    );
  }

  private async doAsyncUpdate(): Promise<void> {
    try {
      this.valueRef.value = await this.asyncCallback();
    } catch (e) {
      if (process.env.NODE_ENV == 'development') {
        Logger.log('Error while computing async ref data');
        Logger.log(e);
      }
      if (this.errCallback) this.errCallback(e);
    }
  }

  get value(): T {
    return this.valueRef.value;
  }

  asRef(): Ref<T> {
    return this.valueRef;
  }
}
