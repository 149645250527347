
import L, { DivIcon, Marker } from 'leaflet';
import {
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  toRefs,
  watch,
} from 'vue';
import { MapMarkerProps, MapPoint } from '@/map-display';
import { Map as LeafletMap } from 'leaflet';
import { BASE_PIN_COLOR } from '@/icon-styling';

export default defineComponent({
  name: 'MapMarker',
  props: {
    point: {
      type: Object as PropType<MapPoint>,
      required: true,
    },
    color: {
      type: String,
    },
    iconClass: {
      type: String,
    },
    zIndex: {
      type: Number,
    },
    map: {
      type: Object as PropType<LeafletMap>,
      required: true,
    },
  },
  render() {
    return [];
  },
  setup(props: MapMarkerProps & { map: LeafletMap }) {
    const { point, color, iconClass, zIndex } = toRefs(props);
    let marker: Marker;
    let icon: DivIcon;

    function buildMarker(): Marker {
      const mark = L.marker(L.latLng(point.value.lat, point.value.lng), {
        icon,
      });
      mark.addTo(props.map);
      return mark;
    }

    function buildIcon(): DivIcon {
      const styleString =
        color?.value && color.value.length > 0
          ? `style='background-color:${props.color}'`
          : `style='background-color:${BASE_PIN_COLOR}'`;
      const htmlIconClass =
        iconClass?.value && iconClass.value.length > 0
          ? iconClass.value
          : 'bi-circle-fill';
      const IW = 36;
      const IH = Math.sqrt(IW * IW * 2);
      return L.divIcon({
        className: 'custom-div-icon',
        html: `<div class='marker-pin leaf-pin' ${styleString}></div><i class='leaflet-map-icon ${htmlIconClass}'></i>`,
        iconSize: [IW, IH],
        iconAnchor: [IW / 2, IH],
      });
    }
    function reloadMarker() {
      if (marker) {
        marker.setLatLng(L.latLng(point.value.lat, point.value.lng));
        marker.setIcon(icon);
        marker.setZIndexOffset(zIndex?.value ? zIndex.value : 0);
      } else {
        marker = buildMarker();
      }
    }
    function reloadIcon() {
      icon = buildIcon();
      reloadMarker();
    }

    onMounted(reloadIcon);
    onUnmounted(() => {
      if (marker) {
        marker.off();
        marker.remove();
      }
    });

    watch(point, reloadMarker, { deep: true });
    if (zIndex) watch(zIndex, reloadMarker);
    if (color) watch(color, reloadIcon);
    if (iconClass) watch(iconClass, reloadIcon);

    icon = buildIcon();
    marker = buildMarker();

    return {
      marker,
      icon,
    };
  },
});
