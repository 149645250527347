import { MapPoint, PointSetPolyInfo } from '@/map-display';
import { ComputedRef, DeepReadonly, Ref } from 'vue';

export interface ComputedPolyline {
  points: MapPoint[];
  displayInfo: PointSetPolyInfo;
}

export type PolylineComputationId = number;

export const ComputedPolylineManager = Symbol('ComputedPolylineManager');

export interface ComputedPolylineManager {
  addPolylineComputation(
    ref: ComputedRef<ComputedPolyline[]>,
  ): PolylineComputationId;
  removePolylineComputation(id: PolylineComputationId): void;

  getPolylines(): DeepReadonly<Ref<ComputedPolyline[]>>;
}
