import { DI } from '@/di';
import {
  ComputedPolyline,
  ComputedPolylineManager,
  PolylineComputationId,
} from '@/services/map-service/points/computed-polylines.interface';
import { ComputedRef, onUnmounted } from 'vue';

export class ManagedComputedPoly {
  private polyService = DI.get<ComputedPolylineManager>(
    ComputedPolylineManager,
  );
  private id: PolylineComputationId = 0;
  private registered = false;
  constructor(private readonly poly: ComputedRef<ComputedPolyline[]>) {}

  private registerPolyComputation() {
    const oldToken = this.id;

    this.id = this.polyService.addPolylineComputation(this.poly);

    if (this.registered) this.polyService.removePolylineComputation(oldToken);

    this.registered = true;
  }

  destroy(): void {
    if (this.registered) this.polyService.removePolylineComputation(this.id);
    this.registered = false;
  }

  setup(): void {
    this.registerPolyComputation();
    onUnmounted(() => {
      this.destroy();
    });
  }
}
