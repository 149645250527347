
import { DI } from '@/di';
import {
  GlobalClickObserver,
  GlobalClickTracker,
} from '@/services/focus/global-click-tracker.interface';
import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  Ref,
  ref,
} from 'vue';

export default defineComponent({
  props: {
    modelValue: String,
  },
  setup(props, { emit }) {
    const editing = ref(false);
    const currentTitle = ref(props.modelValue);
    const input: Ref<HTMLInputElement | null> = ref(null);
    const clickHandler: GlobalClickObserver = {
      onClick: (target: EventTarget) => {
        if (target != input.value) editing.value = false;
      },
    };

    function handleTitleInput(newValue: string) {
      currentTitle.value = newValue;
    }

    function editComplete() {
      editing.value = false;
      emit('update:modelValue', currentTitle.value);
    }

    function headerClicked() {
      editing.value = true;
      nextTick(() => {
        input.value?.focus();
        input.value?.select();
      });
    }

    onMounted(() => {
      DI.get<GlobalClickTracker>(GlobalClickTracker).addObserver(clickHandler);
    });
    onUnmounted(() => {
      DI.get<GlobalClickTracker>(GlobalClickTracker).removeObserver(
        clickHandler,
      );
    });

    return {
      currentTitle,
      editing,
      input,
      editComplete,
      handleTitleInput,
      headerClicked,
    };
  },
});
