import { computed, Ref, ref } from 'vue';
import { GENERIC_TRIP_POINT_ICON_CLASS, CITY_DAY_COLORS } from '@/icon-styling';
import { PointSetDisplayType } from '@/map-display';
import { ComputedPolyline } from '@/services/map-service/points/computed-polylines.interface';
import { CityPointFlattener } from './city-compute';
import { ManagedComputedPoly } from '../ManagedComputedPoly';
import { ManagedPointDisplay } from '../ManagedPointDisplay';
import { CityRouter } from './city-router';

export class OneCityMapDisplay {
  private readonly polyDisplay: ManagedComputedPoly;
  private readonly markerDisplay: ManagedPointDisplay;
  private highlight: Ref<string>;

  constructor(flattener: CityPointFlattener, router: CityRouter) {
    this.highlight = ref('');
    this.markerDisplay = new ManagedPointDisplay(
      computed(() => {
        return flattener.flatCityPoints.value.map((p) => {
          const hlPoint = this.highlight.value === p.id;
          return {
            ...p,
            displayOptions: {
              ...p.displayOptions,
              isHighlighted: hlPoint,
            },
          };
        });
      }),
      PointSetDisplayType.Markers,
    );
    this.markerDisplay.iconClass = GENERIC_TRIP_POINT_ICON_CLASS;

    const polys = computed(() => {
      const dayPolys: ComputedPolyline[] = router.routeData.value.days.map(
        (route, idx) => ({
          points: route.fullRoute,
          displayInfo: {
            color: CITY_DAY_COLORS[idx % CITY_DAY_COLORS.length],
          },
        }),
      );
      return dayPolys;
    });
    this.polyDisplay = new ManagedComputedPoly(polys);
  }

  setHighlight(hlId: string): void {
    this.highlight.value = hlId;
  }

  clearHighlight(hlId: string): void {
    if (this.highlight.value === hlId) {
      this.highlight.value = '';
    }
  }

  setup(): void {
    this.polyDisplay.setup();
    this.markerDisplay.setup();
  }
}
