
import { defineComponent, onUnmounted, PropType } from 'vue';
import { TripCityPoint } from '@/services/trips/trip';

import EditableTitle from '@/components/utils/inputs/EditableTitle.vue';
import CityPointConfig from '@/components/trip-view/CityPointConfig.vue';
import CostDisplay from '@/components/utils/CostDisplay.vue';

import { Debouncer } from '@/utils/Debouncer';

const NOTE_DEBOUNCE = 1000;

export default defineComponent({
  components: {
    EditableTitle,
    CityPointConfig,
    CostDisplay,
  },
  props: {
    modelValue: {
      required: true,
      type: Object as PropType<TripCityPoint>,
    },
  },
  setup(props, { emit }) {
    const changeDebounce = new Debouncer(
      NOTE_DEBOUNCE,
      (data: Partial<TripCityPoint>) => patchAndEmitPoint(data),
      (existing: Partial<TripCityPoint>, newData: Partial<TripCityPoint>) => ({
        ...existing,
        ...newData,
      }),
    );
    function patchAndEmitPoint(patch: Partial<TripCityPoint>) {
      const newCity: TripCityPoint = { ...props.modelValue, ...patch };
      emit('update:modelValue', newCity);
    }

    function scheduleNoteUpdate(event: InputEvent) {
      const notes = (event?.target as HTMLTextAreaElement).value;
      changeDebounce.startDebounce({ notes });
    }

    function scheduleCostUpdate(cost: number) {
      changeDebounce.startDebounce({ cost });
    }

    onUnmounted(() => {
      changeDebounce.fireImmediately();
    });

    return {
      patchAndEmitPoint,
      scheduleCostUpdate,
      scheduleNoteUpdate,
    };
  },
});
