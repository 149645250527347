import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1155e163"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "text-center bi bi-person-fill mt-auto mb-auto",
  id: "um-person-icon"
}
const _hoisted_2 = {
  key: 0,
  class: "alert alert-danger w-100"
}
const _hoisted_3 = ["value"]
const _hoisted_4 = {
  key: 2,
  class: "text-center m-auto p-3",
  id: "um-current-user"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "d-flex flex-column align-items-center",
    id: "usermgmt",
    onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.openForm && _ctx.openForm(...args))),
    ref: "rootDiv",
    onFocusout: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.focusLeaving && _ctx.focusLeaving(...args)))
  }, [
    (!_ctx.expanded && !_ctx.currentUser)
      ? (_openBlock(), _createElementBlock("i", _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.expanded && !_ctx.currentUser)
      ? (_openBlock(), _createElementBlock("form", {
          key: 1,
          class: "d-flex flex-column",
          id: "um-form",
          onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
        }, [
          (_ctx.expanded && !_ctx.currentUser && _ctx.errorMessage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.errorMessage), 1))
            : _createCommentVNode("", true),
          _withDirectives(_createElementVNode("input", {
            class: "form-control",
            id: "um-username",
            ref: "usernameInput",
            type: "text",
            placeholder: "Username",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event))
          }, null, 512), [
            [_vModelText, _ctx.username]
          ]),
          _withDirectives(_createElementVNode("input", {
            class: "form-control",
            id: "um-password",
            type: "password",
            placeholder: "Password",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event))
          }, null, 512), [
            [_vModelText, _ctx.password]
          ]),
          _createElementVNode("input", {
            class: "btn btn-primary",
            id: "um-submit",
            type: "submit",
            value: _ctx.submitText
          }, null, 8, _hoisted_3),
          _createElementVNode("a", {
            class: "btn btn-link p-0",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.doingSignup = !_ctx.doingSignup)),
            href: "#",
            role: "button"
          }, _toDisplayString(_ctx.swapText), 1)
        ], 32))
      : _createCommentVNode("", true),
    (!_ctx.expanded && _ctx.currentUser)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.currentUser.username), 1))
      : _createCommentVNode("", true),
    (_ctx.expanded && _ctx.currentUser)
      ? (_openBlock(), _createElementBlock("input", {
          key: 3,
          class: "btn btn-secondary",
          id: "um-logout",
          ref: "logoutButton",
          type: "submit",
          value: "Log Out",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.doLogout && _ctx.doLogout(...args)))
        }, null, 512))
      : _createCommentVNode("", true)
  ], 544))
}