import { computed, ComputedRef } from 'vue';
import { DI } from '@/di';
import { MapMarkerProps, PointSetDisplayType } from '@/map-display';
import { PointSetService } from '@/services/map-service/points/point-set.interface';

export class PointSetCollapse {
  private readonly service = DI.get<PointSetService>(PointSetService);

  readonly points: ComputedRef<MapMarkerProps[]> = computed(() =>
    this.service
      .getPointSets()
      .value.filter(
        (set) =>
          (set.displayType & PointSetDisplayType.Markers) ===
          PointSetDisplayType.Markers,
      )
      .flatMap((set) =>
        set.points.map((point) => {
          const display = point.displayOptions;
          return {
            point,
            ...display,
            iconClass: display?.iconClass || set.iconClass,
            color: display?.color || set.color,
            zIndex: display?.zIndex || set.zIndex,
          };
        }),
      ),
  );
}
