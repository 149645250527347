
import { computed, defineComponent, ref } from 'vue';
import { SearchResult } from '@/services/map-service/location-search/search-result';

import LocationFinder from '@/components/search/LocationFinder.vue';
import EdPopup from '@/components/utils/EdPopup.vue';
import FaIconModalTrigger from '@/components/utils/modals/FaIconModalTrigger.vue';

export default defineComponent({
  name: 'PointListItem',
  components: {
    LocationFinder,
    EdPopup,
    FaIconModalTrigger,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    allowExpansion: Boolean,
    allowSettings: Boolean,
    allowInline: Boolean,
    allowIntermediate: Boolean,
    allowDelete: {
      type: Boolean,
      default: true,
    },
    closeSettings: Boolean,
    allowCentering: {
      type: Boolean,
      default: true,
    },
    itemClass: String,
    itemDescription: String,
    itemHover: String,
    padAll: Boolean,
    handle: {
      type: Boolean,
      default: true,
    },
    iconClass: String,
    locked: Boolean,
  },
  setup(props, { emit }) {
    const expanded = ref(false);
    const settingsChanging = ref(false);
    const showSettings = computed(
      () => settingsChanging.value && !props.closeSettings,
    );
    const doSettings = computed(
      () => showSettings.value && props.allowSettings,
    );
    const doExpansion = computed(() => expanded.value && props.allowExpansion);
    function emitDelete() {
      emit('delete');
    }

    function emitPointChange(searchItem: SearchResult) {
      emit('searchComplete', searchItem);
    }

    function settingsClicked() {
      settingsChanging.value = !showSettings.value;
      if (settingsChanging.value) emit('settingsOpened');
      else emit('settingsClosed');
    }

    function hideSettings() {
      settingsChanging.value = false;
      emit('settingsClosed');
    }

    function centerMapOnPoint() {
      emit('centerOnThis');
    }

    return {
      expanded,
      settingsChanging,
      settingsClicked,
      showSettings,
      hideSettings,
      emitDelete,
      emitPointChange,
      doSettings,
      doExpansion,
      centerMapOnPoint,
    };
  },
});
