import { TripCity } from '@/services/trips/trip';
import { NormalizedList, normalizeList } from '@/utils/normalized';

export interface CityCostInfo {
  id: string;
  cost: number;
}

export class TotalCityCostCalculator {
  constructor(private readonly cities: TripCity[]) {}

  private getSpecialPointCosts(city: TripCity): number {
    const stay = city.stay_at?.cost || 0;
    const enter = city.enter_via?.cost || 0;
    const exit = city.exit_via?.cost || 0;
    return stay + enter + exit;
  }

  private calculateOneCityCost(city: TripCity): number {
    const fromCity = city.cost || 0;
    const fromPoints = city.points.reduce(
      (acc, point) => acc + (point.cost || 0),
      0,
    );

    const fromSpecial = this.getSpecialPointCosts(city);

    return fromCity + fromPoints + fromSpecial;
  }

  cityCosts(): CityCostInfo[] {
    return this.cities.map((city) => ({
      id: city.id,
      cost: this.calculateOneCityCost(city),
    }));
  }

  normalizedCityCosts(): NormalizedList<CityCostInfo> {
    return normalizeList(this.cityCosts());
  }

  totalCost(): number {
    return this.cityCosts().reduce((acc, cityCost) => acc + cityCost.cost, 0);
  }
}
