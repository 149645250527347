import axios, { AxiosError } from 'axios';
import { injectable } from 'inversify';
import { DateTime } from 'luxon';
import { SearchResult } from '../map-service/location-search/search-result';
import {
  Trip,
  TripCity,
  TripCityPoint,
  TripDetail,
  TripOptions,
  TripPointType,
} from './trip';
import { TripDetailError, TripApiService } from './trip-service.interface';
import { TripTextualizer } from './trip-textualizer.interface';

type ApiTripOptions = Omit<TripOptions, 'startDate'> & { startDate?: string };
type ApiTrip = Omit<TripDetail, '_id' | 'options'> & {
  options: ApiTripOptions;
};

function prepareOptionsForApi(options: TripOptions): ApiTripOptions {
  const startDate = options.startDate
    ? options.startDate.toFormat('yyyy-MM-dd')
    : undefined;
  return { ...options, startDate };
}

function prepareTripForApi(trip: TripDetail): ApiTrip {
  const updatedCities = trip.cities.map((city: TripCity) => {
    const clone: TripCity = { ...city };
    clone.points = clone.points.map((point) => {
      if (point.is_overnight) {
        const with_stay_ll = city.stay_at
          ? {
              ...point,
              lat: city.stay_at.lat,
              lng: city.stay_at.lng,
            }
          : point;
        return with_stay_ll;
      }
      return point;
    }, [] as number[]);
    return clone;
  });
  const options = prepareOptionsForApi(trip.options);
  const { _id, ...subtrip } = trip;
  const newTripData = { ...subtrip, cities: updatedCities, options };
  return newTripData;
}

interface ConvertableId {
  _id: string;
  id?: string;
}

function convertId(v?: ConvertableId) {
  if (v) v.id = v._id;
}

// TODO can we properly type the API route as well
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function normalizeTripForUI(trip: any): TripDetail {
  trip.cities.forEach(
    (
      v: ConvertableId & {
        stay_at?: ConvertableId;
        enter_via?: ConvertableId;
        exit_via?: ConvertableId;
        points: { _id: string; id?: string }[];
      },
    ) => {
      convertId(v);
      convertId(v.stay_at);
      convertId(v.enter_via);
      convertId(v.exit_via);
      v.points.forEach(convertId);
    },
  );

  trip.cities.forEach(
    (city: {
      nights?: number;
      notes?: string;
      points: { minutes?: number; is_overnight?: boolean; cost?: number }[];
    }) => {
      if (city.nights === undefined) {
        city.nights = 0;
      }
      if (city.notes === undefined) {
        city.notes = '';
      }
      city.points.forEach((pt) => {
        if (pt.minutes === undefined) {
          pt.minutes = 0;
        }
        if (pt.is_overnight === undefined) {
          pt.is_overnight = false;
        }
        if (pt.is_overnight) {
          pt.cost = undefined;
        }
      });
    },
  );

  trip.cities.forEach((city: TripCity) => {
    if (!city.stay_at) return;
    const stay_at = city.stay_at;

    let overnightCount = 0;
    city.points = city.points.reduce((newPoints, aliasedPoint) => {
      const point = aliasedPoint as TripCityPoint;
      if (point.is_overnight) {
        ++overnightCount;
        const with_stay_ll = {
          ...point,
          id: `overnight-${overnightCount}`,
          lat: stay_at.lat,
          lng: stay_at.lng,
        };
        newPoints.push(with_stay_ll);
      } else {
        newPoints.push(point);
      }
      return newPoints;
    }, [] as TripCityPoint[]);
  });

  if (trip.options?.startDate) {
    trip.options.startDate = DateTime.fromISO(trip.options.startDate);
  }

  if (!trip.notes) {
    trip.notes = '';
  }

  return trip;
}

@injectable()
export class BasicTripService implements TripApiService, TripTextualizer {
  generatedIdents = 0;
  async getCurrentUserTrips(): Promise<Trip[] | null> {
    try {
      const result = await axios.get<Trip[]>('/trip/mine');
      return result.data;
    } catch (e) {
      return null;
    }
  }

  async getTripDetail(trip: string): Promise<TripDetail | TripDetailError> {
    try {
      const result = await axios.get(`/trip/${trip}`);
      return normalizeTripForUI(result.data);
    } catch (e) {
      const axiosErr = e as AxiosError;
      if (axiosErr.response?.status === 404) return TripDetailError.NOT_FOUND;
      else {
        return TripDetailError.UNKNOWN;
      }
    }
  }
  async updateTrip(trip: TripDetail): Promise<boolean> {
    const normalized = prepareTripForApi(trip);
    try {
      await axios.put(`/trip/${trip._id}`, normalized);
      return true;
    } catch (e) {
      return false;
    }
  }
  async newTrip(name: string): Promise<Trip | null> {
    try {
      const result = await axios.post<Trip>('/trip', { name });
      return result.data;
    } catch (e) {
      return null;
    }
  }

  createCityFromSearch(result: SearchResult): TripCity {
    return {
      lat: result.location.lat,
      lng: result.location.lng,
      name: result.name,
      place_id: result.place_id,
      enter_via: null,
      exit_via: null,
      stay_at: null,
      nights: 0,
      points: [],
      id: `${this.generatedIdents++}`,
      notes: '',
    };
  }

  createPointFromSearch(result: SearchResult): TripCityPoint {
    return {
      lat: result.location.lat,
      lng: result.location.lng,
      name: result.name,
      place_id: result.place_id,
      type: TripPointType.OTHER,
      id: `${this.generatedIdents++}`,
      minutes: 0,
      outgoing_travel: null,
      is_overnight: false,
      notes: '',
    };
  }

  tripToText(trip: TripDetail): string {
    // Need to preserve the ID, so add it in since the API doesn't want it
    // in the JSON
    const normalized: ApiTrip & { _id?: string } = prepareTripForApi(trip);
    normalized._id = trip._id;

    return JSON.stringify(normalized);
  }

  textToTrip(data: string): string | TripDetail {
    try {
      return normalizeTripForUI(JSON.parse(data));
    } catch (e) {
      return (e as Error).message;
    }
  }
}
