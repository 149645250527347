import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ed_modal = _resolveComponent("ed-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      class: "modal-trigger text-nowrap btn btn-sm btn-secondary m-1",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.control.modalOpened()), ["stop"])),
      onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.control.modalTriggerEntered()))
    }, _toDisplayString(_ctx.text), 33),
    (_ctx.show)
      ? (_openBlock(), _createBlock(_component_ed_modal, {
          key: 0,
          onHide: _cache[2] || (_cache[2] = ($event: any) => (_ctx.control.modalHidden()))
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }))
      : _createCommentVNode("", true)
  ], 64))
}