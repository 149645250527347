
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CostDisplay',
  emits: ['update:cost'],
  props: {
    cost: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    function updateCost(evt: InputEvent) {
      try {
        const value = parseInt((evt.target as HTMLInputElement).value);
        emit('update:cost', value);
      } catch (e) {
        // suppress the value, someone is putting in garbage
      }
    }
    return {
      updateCost,
    };
  },
});
