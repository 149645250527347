import 'reflect-metadata';
import { Container } from 'inversify';
import { BasicOsmTileService } from './services/map-service/basic-osm-tiles.service';
import { MapTileService } from './services/map-service/map-tile.interface';
import { LocationSearchService } from './services/map-service/location-search/location-search.interface';
import { SearchResult } from './services/map-service/location-search/search-result';
import { UserInfoService } from './services/user-management/user-info.interface';
import { BasicUserInfoService } from './services/user-management/basic-user-info.service';
import { TripApiService } from './services/trips/trip-service.interface';
import { BasicTripService } from './services/trips/basic-trip.service';
import { PointSetService } from './services/map-service/points/point-set.interface';
import { ArrayPointSetService } from './services/map-service/points/array-point-set.service';
import { MapDisplayInfoService } from './services/map-service/display-info/map-display-info.interface';
import { OpenLayersMapDisplayInfoService } from './services/map-service/display-info/openlayers-map-display-info.service';
import { TripIconSet } from './services/trips/trip-icon-set.interface';
import { FAIconSet } from './services/trips/fontawesome-icon-set.service';
import { DirectionRouterService } from './services/map-service/routing/direction-router.interface';
import { TripTextualizer } from './services/trips/trip-textualizer.interface';
import { ComputedPolylineManager } from './services/map-service/points/computed-polylines.interface';
import { ArrayPolylineManager } from './services/map-service/points/array-polylines.service';
import { MapVectorJsonService } from './services/map-service/map-vector-json.interface';
import { LocalOpenMapTilesVectorJsonService } from './services/map-service/local-openmaptiles.service';
import { PhotonSearchService } from './services/map-service/location-search/photon-search.service';
import { ConsoleLogger } from './services/logging/console-logger.service';
import { LoggerService } from './services/logging/logger.interface';
import { BackendRouterService } from './services/map-service/routing/backend-router.service';
import { TimezoneLookupService } from './services/timezone/timezone-lookup.interface';
import { ServerTzLookupService } from './services/timezone/server-tz-lookup.service';
import { GlobalClickTracker } from './services/focus/global-click-tracker.interface';
import { DocumentClickTracker } from './services/focus/document-click-tracker.service';
import { GeoapifyTileService } from './services/map-service/geoapify-tiles.service';

export const DI = new Container();

export type SearchServiceResultType = SearchResult;

export function bindServices(): void {
  DI.bind<MapTileService>(MapTileService)
    .to(BasicOsmTileService)
    .inSingletonScope();
  if (process.env.VUE_APP_USE_GEOAPIFY_TILES) {
    DI.bind<MapVectorJsonService>(MapVectorJsonService)
      .to(GeoapifyTileService)
      .inSingletonScope();
  } else {
    DI.bind<MapVectorJsonService>(MapVectorJsonService)
      .to(LocalOpenMapTilesVectorJsonService)
      .inSingletonScope();
  }

  DI.bind<LocationSearchService<SearchResult>>(LocationSearchService)
    .to(PhotonSearchService)
    .inSingletonScope();

  DI.bind<DirectionRouterService>(DirectionRouterService)
    .to(BackendRouterService)
    .inSingletonScope();

  DI.bind<UserInfoService>(UserInfoService)
    .to(BasicUserInfoService)
    .inSingletonScope();

  DI.bind<TripApiService>(TripApiService)
    .to(BasicTripService)
    .inSingletonScope();

  DI.bind<TripTextualizer>(TripTextualizer)
    .to(BasicTripService)
    .inSingletonScope();

  DI.bind<PointSetService>(PointSetService)
    .to(ArrayPointSetService)
    .inSingletonScope();
  DI.bind<ComputedPolylineManager>(ComputedPolylineManager)
    .to(ArrayPolylineManager)
    .inSingletonScope();

  DI.bind<MapDisplayInfoService>(MapDisplayInfoService)
    .to(OpenLayersMapDisplayInfoService)
    .inSingletonScope();

  DI.bind<TripIconSet>(TripIconSet).to(FAIconSet).inSingletonScope();

  DI.bind<LoggerService>(LoggerService).to(ConsoleLogger).inSingletonScope();

  DI.bind<TimezoneLookupService>(TimezoneLookupService)
    .to(ServerTzLookupService)
    .inSingletonScope();

  DI.bind<GlobalClickTracker>(GlobalClickTracker)
    .to(DocumentClickTracker)
    .inSingletonScope();
}
