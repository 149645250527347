import { injectable } from 'inversify';
import Map from 'ol/Map';
import { MapDisplayInfoService } from './map-display-info.interface';
import { fromLonLat, toLonLat } from 'ol/proj';
import { MapPoint } from '../../../map-display';

@injectable()
export class OpenLayersMapDisplayInfoService implements MapDisplayInfoService {
  private map?: Map;

  setMap(map: Map | undefined): void {
    this.map = map;
  }
  getMapCenter(): MapPoint | undefined {
    if (!this.map) return undefined;
    const center = this.map.getView().getCenter();
    if (!center) return { lng: 0, lat: 0 };
    const ll = toLonLat(center);
    return { lng: ll[0], lat: ll[1] };
  }

  setMapCenter(mapPoint: MapPoint): void {
    this.map?.getView().animate({
      center: fromLonLat([mapPoint.lng, mapPoint.lat]),
      duration: 250,
    });
  }

  getMapZoom(): number | undefined {
    if (!this.map) return undefined;
    return this.map?.getView().getZoom();
  }
}
