import { computed, ComputedRef, Ref } from 'vue';

export function hoursAndMinutesComputer(
  seconds: Ref<number>,
): ComputedRef<string> {
  return computed(() => hoursAndMinutes(seconds.value));
}

export function hoursAndMinutes(seconds: number): string {
  const totalMinutes = Math.ceil(seconds / 60);
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);

  const useMinutes = minutes > 0 || hours == 0;

  const hourString = hours > 0 ? `${hours}h` : '';
  const minuteString = useMinutes ? `${minutes}m` : '';
  if (hourString.length > 0 && minuteString.length > 0)
    return `${hourString} ${minuteString}`;
  else return hourString + minuteString;
}
