import { TripCity } from '@/services/trips/trip';
import { NormalizedList, normalizeList } from '@/utils/normalized';
import { DateTime } from 'luxon';

export interface CityStartDate {
  cityId: string;
  date: DateTime;
}

export function cityStartDates(
  cities: TripCity[],
  firstStart: DateTime,
): CityStartDate[] {
  let nights = 0;
  return cities.map((c) => {
    const result = {
      cityId: c.id,
      date: firstStart.plus({ days: nights }),
    };
    nights += c.nights;
    return result;
  });
}

export function normalizedCityStartDates(
  cities: TripCity[],
  firstStart: DateTime,
): NormalizedList<CityStartDate & { id: string }> {
  const dates = cityStartDates(cities, firstStart);
  const convertedInfo = dates.map((dateInfo) => ({
    id: dateInfo.cityId,
    ...dateInfo,
  }));
  return normalizeList(convertedInfo);
}
