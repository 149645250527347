import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_point_list_item = _resolveComponent("point-list-item")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_hidden_finder = _resolveComponent("hidden-finder")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_draggable, {
      modelValue: _ctx.draggableList,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.draggableList) = $event)),
      group: _ctx.actualDragGroup,
      "item-key": "id",
      handle: ".pli-handle",
      "force-fallback": true
    }, {
      item: _withCtx(({element}) => [
        _createElementVNode("div", null, [
          _createVNode(_component_point_list_item, {
            class: "w-100 mb-1 flex-grow-1",
            name: element.name,
            "allow-expansion": !!_ctx.$slots.expansion,
            "allow-settings": !!_ctx.$slots.settings,
            "allow-inline": !!_ctx.$slots.inline,
            "item-class": _ctx.itemClass,
            "pad-all": _ctx.padAll,
            locked: !!element.locked,
            "icon-class": _ctx.idIconMap ? _ctx.idIconMap[element.id] : undefined,
            style: _normalizeStyle(_ctx.getItemBorder(element.id)),
            onSearchComplete: ($event: any) => (_ctx.changePoint(element.id, $event)),
            onDelete: ($event: any) => (_ctx.deletePoint(element.id)),
            onCenterOnThis: ($event: any) => (_ctx.centerOnItem(element.id)),
            onMouseover: ($event: any) => (_ctx.$emit("itemHoverStart", element.id)),
            onMouseleave: ($event: any) => (_ctx.$emit("itemHoverEnd", element.id))
          }, {
            inline: _withCtx(() => [
              _renderSlot(_ctx.$slots, "inline", {
                point: element,
                updatePoint: (p) => _ctx.updatePoint(element.id, p)
              })
            ]),
            settings: _withCtx(() => [
              _renderSlot(_ctx.$slots, "settings", {
                point: element,
                updatePoint: (p) => _ctx.updatePoint(element.id, p)
              })
            ]),
            expansion: _withCtx(() => [
              _renderSlot(_ctx.$slots, "expansion", {
                point: element,
                updatePoint: (p) => _ctx.updatePoint(element.id, p)
              })
            ]),
            _: 2
          }, 1032, ["name", "allow-expansion", "allow-settings", "allow-inline", "item-class", "pad-all", "locked", "icon-class", "style", "onSearchComplete", "onDelete", "onCenterOnThis", "onMouseover", "onMouseleave"]),
          _renderSlot(_ctx.$slots, "intermediate", {
            point: element,
            updatePoint: (p) => _ctx.updatePoint(element.id, p)
          })
        ])
      ]),
      _: 3
    }, 8, ["modelValue", "group"]),
    _createVNode(_component_hidden_finder, {
      class: "w-100",
      "clear-on-chosen": true,
      onLocationChosen: _ctx.addPoint,
      "focus-on-chosen": true
    }, null, 8, ["onLocationChosen"])
  ]))
}