import { injectable } from 'inversify';
import { MapTileService } from './map-tile.interface';

@injectable()
export class BasicOsmTileService implements MapTileService {
  buildLeafletTileUrl(): string {
    return 'https://{s}.tile.osm.org/{z}/{x}/{y}.png';
  }
  buildOptions(): Record<string, unknown> {
    return {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    };
  }
}
