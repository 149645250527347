
import { defineComponent } from 'vue';
import { DI } from '../di';
import { UserInfoService } from '../services/user-management/user-info.interface';

export default defineComponent({
  name: 'Hud',
  setup() {
    const userService = DI.get<UserInfoService>(UserInfoService);
    const currentUser = userService.getCurrentUser();

    return { currentUser };
  },
});
