import { ref } from 'vue';

export class ModalControl {
  /*
   * Tracking for hide, to prevent reopening if the button is clicked to close
   * the modal.
   *
   * When hidden, we'll assume it was local. We'll correct that assumption if
   * the mouse enters the button.
   */
  readonly showModal = ref(false);
  private readonly wasLocalHide = ref(false);

  modalOpened(): void {
    if (!this.wasLocalHide.value) this.showModal.value = true;
    this.wasLocalHide.value = false;
  }

  modalHidden(): void {
    this.wasLocalHide.value = true;
    this.showModal.value = false;
  }

  modalTriggerEntered(): void {
    this.wasLocalHide.value = false;
  }
}
