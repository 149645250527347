import { CITY_DAY_COLORS } from '@/icon-styling';

export function getOvernightIconMap(
  nights: number,
  iconClasses: string,
): Record<string, string> {
  const records: Record<string, string> = {};
  for (let i = 0; i <= nights; ++i) {
    records[`overnight-${i}`] = iconClasses;
  }
  return records;
}

export function getOvernightBorderColors(
  nights: number,
): Record<string, string> {
  const records: Record<string, string> = {};
  for (let i = 0; i <= nights; ++i) {
    records[`overnight-${i}`] = CITY_DAY_COLORS[i % CITY_DAY_COLORS.length];
  }
  return records;
}
