import { TripDetail } from './trip';

export const TripTextualizer = Symbol('TripTextualizer');

export interface TripTextualizer {
  tripToText(trip: TripDetail): string;
  /// Attempts to convert the string back into the trip details.
  /// If that fails, returns an error message instead.
  textToTrip(data: string): TripDetail | string;
}
