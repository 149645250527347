import { injectable } from 'inversify';
import { Map } from 'leaflet';
import { MapPoint } from '../../../map-display';
import { MapDisplayInfoService } from './map-display-info.interface';

@injectable()
export class LeafletMapDisplayInfoService implements MapDisplayInfoService {
  private map?: Map;

  setMap(map: Map | undefined): void {
    this.map = map;
  }
  getMapCenter(): MapPoint | undefined {
    if (!this.map) return undefined;
    return this.map.getBounds().getCenter();
  }

  setMapCenter(mapPoint: MapPoint): void {
    this.map?.panTo(mapPoint);
  }

  getMapZoom(): number | undefined {
    return this.map?.getZoom();
  }
}
