
import { RouteOptionMap } from '@/parts/city/city-router';
import { RouteType } from '@/services/map-service/routing/route-type';
import { TripCityPoint } from '@/services/trips/trip';
import { computed, defineComponent, PropType } from 'vue';
import LegInfoDisplay from './LegInfoDisplay.vue';

const MAX_LEG_SECONDS = 60 * 60 * 24;

const LEG_DISPLAY_ORDER = [RouteType.WALK, RouteType.CAR];

export default defineComponent({
  name: 'PointTravelInfo',
  components: {
    LegInfoDisplay,
  },
  props: {
    modelValue: {
      type: Object as PropType<TripCityPoint>,
      required: true,
    },
    route: {
      type: Object as PropType<RouteOptionMap>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const pointRouteTypes = computed(() =>
      Array.from(props.route.keys())
        .filter((type) => {
          const leg = props.route.get(type);
          return leg && leg.seconds > 0 && leg.seconds < MAX_LEG_SECONDS;
        })
        .sort(
          (a, b) => LEG_DISPLAY_ORDER.indexOf(a) - LEG_DISPLAY_ORDER.indexOf(b),
        ),
    );
    function getTypeInfoBinding(type: RouteType) {
      const binding = {
        type,
        seconds: props.route.get(type)?.seconds,
        selected: props.modelValue.outgoing_travel === type,
      };
      return binding;
    }
    function setSelectedTravelType(type: RouteType) {
      const copy = { ...props.modelValue };
      copy.outgoing_travel = type;
      emit('update:modelValue', copy);
    }
    return {
      pointRouteTypes,
      getTypeInfoBinding,
      setSelectedTravelType,
    };
  },
});
